body#product-page
  .image-notice
    padding-right: $spacing-lg
  section
    background-color: $color-white
    position: relative
    z-index: 1
    &#landing
      $height: 65vh
      height: $height
      & > .wide-container
        height: 100%
      #landing-bg
        position: fixed
        top: 0
        left: 0
        display: block
        left: max(0px, calc(50vw - (($wide-container-width + $container-padding) / 2)))
        right: max(0px, calc(50vw - (($wide-container-width + $container-padding) / 2)))
        height: $height
        object-fit: cover
        z-index: 0
        .image
          height: 100%
        img
          max-height: $height
        &.scrolled
          position: absolute
      .logo
        position: absolute
        top: clamp(80px, 8vw, 150px)
        left: 17vw
        width: clamp(150px, 16vw, 320px)
        z-index: 10
      .landing-content
        position: absolute
        padding: $margin-sm $margin-md
        background-color:  rgba(12, 13, 13, 0.5)
        color: $color-light
        bottom: 10%
        right: 20%
        z-index: 10
      .landing-content.lx
        padding: $margin-md
        left: 10%
        right: auto
        background-color:  rgba(12, 13, 13, 0.7)
        bottom: 2%

    &#product,
    &#detail,
    &#lineup,
    &#gallery,
    &#video
      padding-top: $margin-xxl

    &#product
      .main-row
        gap: $margin-xxl
        .left
          flex: 1
        .right
          flex: 2
          padding-top: $margin-md
          &.bg-primary
            .image
              background-color: $color-primary
          .image
            width: clamp(260px, 19vw, 360px)
            margin: auto
            p
              max-width: 80%
              margin: auto

    &#detail
      .row
        &:nth-child(odd)
          flex-direction: row-reverse
        .left
          flex: 7
          p
            width: clamp(280px, 35vw, 640px)
            margin: auto
            text-align: left
        .right
          flex: 6

      .image
        // $height: clamp(100px, 33vw, 450px)
        // $width: clamp(300px, 100vw, 1920px)
        width: 100%
        height: 450px
        position: relative
        overflow: hidden
        img
          position: absolute
          top: 0
          left: 0
          width: 100%
          height: 100%
          object-fit: cover
          object-position: center

    &#full-width
      position: relative
      background-color: $color-white
      $height: clamp(100px, 33vw, 637px)
      $width: clamp(300px, 100vw, 1920px)
      padding-top: $margin-md
      overflow: hidden
      .wide-container
        position: relative
        overflow: hidden
        height: $height
        width: $width
        // background-image: url('./assets/images/xf-65/full-width.png')
        // background-size: $width, $height
        // background-repeat: no-repeat
        // background-position: center
        // background-size: cover
        .image
          position: absolute
          inset: 0

    &#lineup
      .red
        color: $color-red
      .grey
        color: $color-grey
      .lineup-grid
        display: grid
        grid-template-columns: 1fr 1fr 1fr
        gap: $margin-md
        .wheel
          .image
            width: 80%
            margin: auto
      .order-link
        width: clamp(200px, 50%, 400px)
        padding: $spacing-sm $spacing-xl
        font-size: $font-md
        &:hover::before
          max-height: 70px
          max-width: 400px
      .banner
        display: block
        img
          margin: auto
      .specs
        overflow-x: auto
        table, th, td
          border: 1px solid $color-light
          white-space: nowrap
        table
          min-width: 100%
          position: relative
          border-collapse: collapse
          thead
            background-color: $color-primary
            color: $color-light
          tr:nth-child(odd)
            // background-color: rgba($color-primary, 0.1)
          th, td
            padding: 4px $spacing-sm
            text-align: center
            &.no-bg
              background: $color-white
          th
            position: sticky
            top: 0
            z-index: 9999999999
          td
            &.comment
              text-align: left
          .accent
            background-color: $color-secondary
            color: $color-white
          .greyed
            color: $color-grey
      .info
        .row
          gap: $margin-md
          row-gap: $spacing-xs
        .info-item
          line-height: 1.9
          &::before
            margin-right: $spacing-xs
          &.double
            & > div
              width: calc(100% - 30px)
        .square
          &::before
            content: '■'
        .circle
          &::before
            content: '●'
        .notice
          &::before
            content: '※'

    &#gallery
      padding-bottom: $margin-xxl
      .gallery-grid
        display: grid
        grid-template-columns: 1fr 1fr 1fr
        gap: $margin-xl
        .image
          cursor: pointer
          overflow: hidden
          max-height: 280px
          &:hover
            opacity: 0.85
            img
              transform: scale(1)
          img
            transition: 300ms ease-in-out
            transform: scale(1.05)





@media screen and (max-width: $breakpoints-sm)
  body#product-page
    section
      &#landing
        $height: 50vh
        height: $height
        #landing-bg
          height: $height
          img
            max-height: $height
        .wide-container
          // height: $height
          padding: 0
          & > .image
            height: 100%
        .logo
          display: none
        //   left: 5vw
        //   top: auto
        //   bottom: 0
        //   width: clamp(220px, 40vw, 280px)
        .landing-content
          bottom: 8px
          right: 0
          left: 0
          font-size: $font-sm
          padding: $spacing-md $spacing-xs
          br
            display: none

      &#product,
      &#detail,
      &#lineup,
      &#gallery,
      &#video
        padding-top: $margin-lg

      &#product
        .main-row
          flex-direction: column
          gap: $margin-md
          .left
            .image
              max-width: 80%
              margin: auto
          .right
            flex: 1
            padding-top: 0
            h2
              margin-top: $margin-md
              margin-bottom: $margin-sm
      &#detail
        margin-top: $margin-md
        .detail-row
          flex-direction: column-reverse!important
          margin-top: $margin-md
          .left
            margin-top: $margin-md
            h3
              margin-bottom: $font-sm
      &#lineup
        margin-top: $margin-md
        .lineup-grid
          // grid-template-columns: 1fr
          gap: $margin-xs
      &#gallery
        // padding-top: $margin-md
        .gallery-grid
          margin-top: $margin-md
          grid-template-columns: 1fr 1fr
          gap: $margin-xs
          gap: $margin-xs
      &#video
        margin-top: $margin-md



@media screen and (max-width: $breakpoints-xs)
  body#product-page
    section
      &#product,
      &#detail,
      &#lineup,
      &#gallery,
      &#video
        padding-top: $margin-sm

      &#lineup
        .lineup-grid
          grid-template-columns: 1fr
      &#gallery
        padding-bottom: $margin-md
