.animated-btn
  position: relative
  appearance: none
  outline: none
  border: none
  box-sizing: border-box
  cursor: pointer
  border-radius: $radius-sm
  background-color: $color-primary
  color: $color-white
  transition: 300ms ease-in-out
  overflow: hidden
  // padding: $spacing-sm $spacing-xl
  // font-size: $font-md
  &::before
    $size: 100%
    position: absolute
    content: ''
    background-color: $color-secondary
    bottom: 0
    right: 0
    width: $size
    height: $size
    max-height: 0
    max-width: 0
    transition: 300ms ease-in-out
  &:hover::before
    max-height: 70px
    max-width: 500px
  span
    position: relative
    z-index: 2
    display: inline-flex
    justify-content: center
    width: 100%
  .loader
    display: none
    position: absolute
    right: $spacing-md
    top: 50%
    transform: translateY(-50%)
