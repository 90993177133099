body#history-page
  main
    background-color: $color-white

  section
    position: relative
    z-index: 10
    background-color: $color-white

    &#landing
      $height: 65vh
      height: $height
      & > .wide-container
        height: 100%
      #landing-bg
        position: fixed
        top: 0
        left: max(0px, calc(50vw - (($wide-container-width + $container-padding) / 2)))
        right: max(0px, calc(50vw - (($wide-container-width + $container-padding) / 2)))
        display: block
        height: $height
        object-fit: cover
        z-index: 0
        .slider,
        .slide,
        .image
          height: $height
        img
          max-height: $height
        &.scrolled
          position: absolute

      .landing-content
        position: absolute
        padding: $margin-sm $margin-md
        background-color:  rgba(12, 13, 13, 0.5)
        color: $color-light
        bottom: 10%
        left: 20%
        z-index: 10
        width: 360px
        box-sizing: border-box
        .font-lg
          text-align: left
        .font-md
          text-align: right

    &#origin
      & > .row
        gap: $margin-xl
        .visual
          width: clamp(200px, 42%, 540px)
          .ame-logo
            width: 300px
          .modelart-logo
            width: 300px
            margin-top: $spacing-sm
            margin-right: $spacing-md
            margin-left: auto
          .first-wheel
            max-width: 230px

        .content
          width: 58%

    &#choice
      .row
        gap: $margin-xxl

    &#choice,
    &#reason
      .left
        width: clamp(300px, 20%, 540px)
      .right
        width: 80%
      p
        margin-top: 0

    &#reason
      .lineup
        gap: $spacing-sm
        .wheel
          position: relative
          cursor: pointer
          &:hover
            .description
              display: block
          .description
            display: none
            position: absolute
            font-size: $font-xs
            white-space: nowrap
            top: 0
            left: 50%
            transform: translate(-50%, -120%)
            padding: 4px $spacing-xs
            background-color: $color-primary
            color: $color-white
            border-radius: $radius-sm
          .image
            width: 70px

    &#bottom-banner
      padding-bottom: $margin-max


  .tns-outer,
  .tns-ovh,
  .tns-inner,
  .slider,
  .slide,
  .slide .image
    height: 100%

  .main-container
    background-color: $color-white
    position: relative
    z-index: 100

@media screen and (max-width: $breakpoints-sm)
  body#history-page
    section
      &#landing
        .landing-content
          width: 280px

      &#origin,
      &#choice,
      &#reason,
      &#bottom-banner
        margin-top: $margin-xxl


@media screen and (max-width: $breakpoints-xs)
  body#history-page
    section
      &#landing
        .landing-content
          left: 10%
          width: 260px

      &#origin,
      &#choice,
      &#reason,
      &#bottom-banner
        margin-top: $margin-xl


      &#origin
        & > .row
          gap: $margin-md
          flex-direction: column
          &.mt-xxl
            margin-top: $margin-lg
          .visual
            width: 100%
            .ame-logo
              width: clamp(220px, 70%, 450px)
            .modelart-logo
              width: clamp(240px, 75%, 450px)
              margin-top: $margin-sm
            .wheel
              justify-content: center
              .inner
                max-width: 60%
                margin: auto

          .content
            width: 100%
            h4
              font-size: 1.5rem
              margin-top: 0

      &#choice,
      &#reason
        & > .row
          flex-direction: column
          gap: $margin-md
          .left
            width: 100%
          .right
            width: 100%

      &#reason
        #mid-slider
          height: clamp(150px, 56vw, 400px)
        .lineup
          gap: $spacing-md
          flex-wrap: wrap
          flex-direction: row
          // display: grid
          // grid-template-columns: 1fr 1fr 1fr
      &#bottom-banner
        padding-bottom: $margin-xl
        #bottom-slider
          height: clamp(150px, 56vw, 400px)
