body#contact-page

  section
    position: relative
    z-index: 10
    background-color: $color-white

    &#landing
      $height: 65vh
      height: $height
      & > .wide-container
        height: 100%
      #landing-bg
        position: fixed
        top: 0
        left: 0
        display: block
        left: max(0px, calc(50vw - (($wide-container-width + $container-padding) / 2)))
        right: max(0px, calc(50vw - (($wide-container-width + $container-padding) / 2)))
        height: $height
        object-fit: cover
        z-index: 0
        .image
          height: 100%
        img
          max-height: $height
        &.scrolled
          position: absolute

      .landing-content
        position: absolute
        padding: $margin-sm $margin-md
        background-color:  rgba(12, 13, 13, 0.5)
        color: $color-light
        bottom: 10%
        right: 20%
        z-index: 10
        width: 160px
        .font-lg
          text-align: left
        .font-md
          text-align: right

    &#contact
      .form_title
        h4
          padding: 4px $spacing-md
          border-bottom: 1px solid $color-secondary

      form#contact_form
        max-width: clamp(300px, 60vw, 650px)
        margin: $margin-xl auto 0 auto
        padding-bottom: $margin-max
        text-align: center
        color: $color-green

        &.loading
          opacity: 0.8
          input, textarea, button
            pointer-events: none
          .loader
            display: inline-flex

        #form_error
          color: $color-red

        .input_group
          display: flex
          flex-direction: column
          color: $color-primary
          label
            min-width: 150px
            text-align: left
          input, textarea
            width: 100%
            padding: $spacing-sm $spacing-md
            background-color: $color-light
            border: none
            border-radius: $radius-sm
            &:active, &:focus
              outline-color: $color-secondary
          textarea
            resize: vertical

        button.animated-btn
          width: 175px
          height: 42px
          &:hover::before
            max-height: 100px
            max-width: 400px



@media screen and (max-width: $breakpoints-sm)
  body#contact-page
    section
      &#landing
        .landing-content
          width: 160px

      &#contact
        p.text,
        .contact_hours,
        .form_title,
        form#contact_form,
        .submit
          margin-top: $margin-lg

        form#contact_form
          .input_group
            label
              min-width: 130px


@media screen and (max-width: $breakpoints-xs)
  body#contact-page
    section


      &#contact
        p.text,
        .contact_hours,
        .form_title,
        form#contact_form,
        .submit
          margin-top: $margin-md

        form#contact_form
          padding-bottom: $margin-xl
          .input_group
            flex-direction: column
            align-items: flex-start
            label
              min-width: 130px
            input, textarea
              width: auto
              align-self: stretch

