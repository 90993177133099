body#shoplist-page
  section
    position: relative
    z-index: 10
    background-color: $color-white

    &#landing
      $height: 65vh
      height: $height
      & > .wide-container
        height: 100%
      #landing-bg
        position: fixed
        top: 0
        left: 0
        display: block
        width: 100%
        height: $height
        object-fit: cover
        z-index: 0
        .image
          height: 100%
        img
          max-height: $height
        &.scrolled
          position: absolute

      .landing-content
        position: absolute
        padding: $margin-sm $margin-md
        background-color:  rgba(12, 13, 13, 0.5)
        color: $color-light
        bottom: 10%
        left: 20%
        z-index: 10
        width: 360px
        box-sizing: border-box
        .font-lg
          text-align: left
        .font-md
          text-align: right

    &#shops
      padding-bottom: $margin-max
      .region-links
        gap: $margin-sm
        .region-link
          padding: 2px $spacing-sm
          border-radius: $radius-sm
          background-color: $color-primary
          color: $color-white

      .map
        $bannerHeight: 60px
        $height: 500px
        width: 100%
        height: $height
        overflow: hidden
        filter: grayscale(40%)

        iframe
          position: relative
          width: 100%
          height: $bannerHeight + $height
          border: none
          top: $bannerHeight * -1
          pointer-events: none

      .region
        padding: 4px $spacing-xl
        background-color: $color-primary
        color: $color-light
        display: inline-flex
        border-radius: $radius-sm
        user-select: none

      .shops-table
        overflow-x: scroll
        table
          width: 100%
          border-collapse: collapse
          th
            color: $color-secondary
            text-align: left
            background-color: $color-light
            position: relative
            &:first-of-type
              &::before
                $size: 20px
                position: absolute
                content: ""
                top: 0
                left: -2px
                border-top: $size solid $color-white
                border-right: $size*2 solid transparent
                border-radius: 0 0 0 $radius-sm
            &:last-of-type
              border-radius: 0 $radius-sm $radius-sm 0
          th, td
            padding: $spacing-sm $margin-md
          tbody tr:nth-child(even)
            background-color: transparentize($color-light, 0.7)

          .name
            text-decoration: underline
            white-space: nowrap
          .phone
            white-space: nowrap



@media screen and (max-width: $breakpoints-sm)
  body#shoplist-page
    section
      &#landing
        .landing-content
          width: 280px
      &#shops
        .map
          $bannerHeight: 60px
          $height: 400px
          height: $height
          iframe
            height: $bannerHeight + $height
            top: $bannerHeight * -1
        .shops-table table
          th
            &:first-of-type::before
              $size: 14px
              border-top: $size solid $color-white
              border-right: $size*2 solid transparent
          th, td
            padding: $spacing-xs $margin-xs





@media screen and (max-width: $breakpoints-xs)
  body#shoplist-page
    section
      &#landing
        .landing-content
          left: 10%
          width: 260px
      &#shops
        .region-links
          gap: $margin-xs
          flex-wrap: wrap
        .map
          $bannerHeight: 60px
          $height: 300px
          height: $height
          margin: $margin-md 0
          iframe
            height: $bannerHeight + $height
            top: $bannerHeight * -1

        .container.mt-max
          margin-top: $margin-md

        .shops-table table
          .name
            white-space: normal


