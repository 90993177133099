body#home-page
  section
    background-color: $color-white
    position: relative
    z-index: 1
    &#landing
      min-height: 100vh
      overflow: hidden
      video
        position: fixed
        top: 0
        left: 0
        display: block
        width: 100%
        height: 100%
        object-fit: cover
        z-index: 0
        &.scrolled
          position: absolute

      .galerna-logo
        position: absolute
        bottom: $margin-xxl
        left: 6vw
        width: clamp(150px, 17vw, 300px)
        color: $color-white
        svg
          display: block
          width: 100%
          height: auto

      .inner
        display: flex
        justify-content: flex-end
        align-items: flex-end
        height: 100%
        min-height: 100vh

      .landing-content
        max-width: clamp(300px,  45vw, 700px)
        padding-bottom: $margin-xxl
        // padding-right: $margin-sm

      .scroll-down
        position: absolute
        bottom: 10px
        left: $margin-md
        z-index: 20
        color: $color-light
        user-select: none

        .label
          transform: rotate(-90deg)
          transform-origin: left

        .arrow
          width: 12px
          margin-left: -4px
          svg
            display: block
            width: 100%
            height: auto
            animation: grow 6s linear infinite alternate
            path
              stroke: $color-light

    &#new-models
      padding-top: $margin-xl
      .new-wheels
        display: grid
        grid-template-columns: 1fr 1fr 1fr 1fr
        gap: $margin-lg

      @for $i from 1 through 4
        article.new-wheel:nth-child(#{$i})
          .image
            transition-delay: 0s + ($i * 0.15)

      .new-wheel
        .image
          position: relative
          // transition: transform 2s cubic-bezier(0.68, -0.55, 0.27, 1.55)
          img
            margin: auto
            transition: transform 2s cubic-bezier(0.55, -0.55, 0.2, 2.25)
          .logo
            opacity: 0
            position: absolute
            right: 0
            bottom: 0px
            padding: 0rem 1rem
            background-color: rgba($color-white, 0.75)
            animation: 0.3s ease-in-out 2.1s forwards fadein
            &.galerna
              padding: 8px 14px
              svg
                height: 20px
            img
              height: 38px
              width: auto

        .content
          transition: opacity 1000ms ease-in
          transition-delay: 600ms
        h3
          align-self: center
        .wheel-tag
          align-self: center
          background-color: $color-secondary
          color: $color-light
          padding: 2px 8px
          border-radius: 2px
          font-size: $font-sm
          &.invisible
            visibility: hidden

    &#lineup
      padding-top: $margin-max

      section.sub-brand
        &:nth-child(odd)
          .top-row
            flex-direction: row-reverse
        &#shallen .sub-brand-image .image
          background-image: url('./assets/images/home/shallen-top-image.jpg')
        &#modelart .sub-brand-image .image
          background-image: url('./assets/images/home/modelart-top-image.jpg')
        &#tracer .sub-brand-image .image
          background-image: url('./assets/images/home/tracer-top-image.jpg')
        &#galerna .sub-brand-image .image
          background-image: url('./assets/images/home/galerna-top-image.jpg')
        .top-row
          gap: $margin-xxl
          .left, .right
            flex: 1
          .right
            padding: $margin-max 0
          .sub-brand-image
            max-width: clamp(250px, 100%, 850px)
            height: 100%
            $borderX: clamp(150px, 15vw, 275px)
            $borderY: clamp(80px, 6vw, 150px)
            &.border-left
              .image::before
                // border-top: 20vw solid $color-white
                // border-right: 20vw solid transparent
                border-top: $borderY solid $color-white
                border-left: $borderX solid $color-white
                border-bottom: $borderY solid transparent
                border-right: $borderX solid transparent
            &.border-right
              .image::before
                right: 0
                border-top: $borderY solid $color-white
                border-right: $borderX solid $color-white
                border-left: $borderX solid transparent
                border-bottom: $borderY solid transparent

            .image
              width: 100%
              height: clamp(300px, 100%, 700px)
              position: relative
              overflow: hidden
              background-attachment: fixed
              background-position: center
              background-repeat: no-repeat
              background-size: auto clamp(250px, 100%, 850px)
              &::before
                position: absolute
                content: ''
                width: 0
                z-index: 1

          .sub-brand-logo
            max-width: clamp(300px, 20vw, 400px)
            margin: $margin-lg auto $margin-xs auto
            svg
              display: block
              width: 100%
              height: auto
              path
                &.accent
                  fill: #6D6E71
          h3
            white-space: pre-line
          .description
            width: clamp(250px, 25vw, 450px)
            margin: auto
        .wheels-grid
          margin-top: 100px
        .wheels-row
          display: grid
          grid-template-columns: 1fr 1fr 1fr 1fr
          gap: $margin-lg
        .wheel
          .image
            position: relative
            .wheel-img
              transition: 400ms ease-in-out
          .new
            position: absolute
            right: 0
            bottom: 0
            width: 65px
            height: auto


          // &:hover
          //   .wheel-img
              // transform: translateY(-4px)
          h3
            white-space: pre-line
          .sizes
            gap: $spacing-lg
            row-gap: $spacing-xs
        .banner
          display: block
    &#links
      padding-top: $margin-max
      padding-bottom: $margin-xl
      .container
        display: grid
        grid-template-columns: 1fr 1fr 1fr
        gap: 16px 8px
        &:nth-child(even)
          grid-template-columns: 1fr 1fr
        a.link
          position: relative
          &:hover
            .image img
              transform: scale(1)
          .image
            width: 80%
            height: 200px
            margin-left: auto
            overflow: hidden
            img
              transition: 300ms ease-in-out
              transform: scale(1.05)
          .title
            position: absolute
            top: 50%
            left: 0
            transform: translateY(-50%)
            & > div
              background-color: rgba(12, 13, 13, 0.5)
              padding: 2px 8px
            .sub-label
              font-size: 1.25rem
              font-style: italic
            .main-label
              margin-left: $margin-md

  *[data-animation="fadein"]
    transition: all 800ms ease-in


@media screen and (max-width: $breakpoints-sm)
  body#home-page
    section
      &#landing
        .landing-content
          max-width: clamp(300px,  70vw, 700px)
          // padding-bottom: $margin-sm
          padding-right: 0
          h2
            font-size: 1.15rem
          p
            font-size: $font-sm
        .galerna-logo
          bottom: $margin-sm
          left: $margin-xl
        .scroll-down
          bottom: 0
          left: $margin-sm

      &#new-models
        .new-wheels
          grid-template-columns: 1fr 1fr
          gap: $margin-lg
          overflow: hidden
          margin-top: $margin-xxl
          .new-wheel
            .image
              max-width: clamp(150px, 80%, 340px)
              margin: auto
            .content
              margin-top: $margin-md
              p
                max-width: 70%
                margin: $margin-md auto
            .wheel-tag
              margin-top: $margin-xs

      &#lineup
        section.sub-brand
          margin-top: $margin-xxl
          .top-row, .top-row:nth-child(odd)
            flex-direction: column
            gap: 0
          .top-row
            .right
              padding: $margin-xl 0
            .sub-brand-image
              $borderX: clamp(75px, 15vw, 150px)
              $borderY: clamp(40px, 10vw, 100px)
              &.border-left
                .image::before
                  border-top: $borderY solid $color-white
                  border-left: $borderX solid $color-white
                  border-bottom: $borderY solid transparent
                  border-right: $borderX solid transparent
              &.border-right
                .image::before
                  right: 0
                  border-top: $borderY solid $color-white
                  border-right: $borderX solid $color-white
                  border-left: $borderX solid transparent
                  border-bottom: $borderY solid transparent
          .wheels-grid
            margin-top: $margin-lg
            .wheels-row
              grid-template-columns: 1fr 1fr
              .image
                max-width: clamp(150px, 80%, 340px)
                margin: auto

      &#links
        padding-bottom: $margin-md
        padding-top: $margin-xxl
        .container
          grid-template-columns: 1fr !important
          gap: $spacing-sm
          a.link
            .image
              width: 100%
              height: 40vw
              img
                width: 100%
                height: 100%
                object-fit: cover
                object-position: center
            .title
              transform: none
              top: auto
              left: auto
              bottom: 6px
              right: 6px
              color: $color-light
            .main-label
              font-size: $font-rg

@media screen and (max-width: $breakpoints-xs)
  body#home-page
    section
      &#landing
        .landing-content
          width: 80%
          max-width: none
          margin-left: auto
          margin-right: auto
      &#new-models
        .new-wheels
          grid-template-columns: 1fr
      &#lineup
          section.sub-brand
            .wheels-grid
              .wheels-row
                grid-template-columns: 1fr
