body#order-page
  section
    position: relative
    z-index: 10
    background-color: $color-white

    &#landing
      $height: 65vh
      height: $height
      & > .wide-container
        height: 100%
      #landing-bg
        position: fixed
        top: 0
        left: 0
        display: block
        left: max(0px, calc(50vw - (($wide-container-width + $container-padding) / 2)))
        right: max(0px, calc(50vw - (($wide-container-width + $container-padding) / 2)))
        height: $height
        object-fit: cover
        z-index: 0
        .image
          height: 100%
        img
          max-height: $height
        &.scrolled
          position: absolute

      .landing-content
        position: absolute
        padding: $margin-sm $margin-md
        background-color:  rgba(12, 13, 13, 0.5)
        color: $color-light
        bottom: 10%
        right: 20%
        z-index: 10
        width: 260px
        .font-lg
          text-align: left
        .font-md
          text-align: right

  .flow
    max-width: 800px
    margin: $margin-xl auto
  #order_form
    width: clamp(300px, 50vw, 620px)
    margin: $margin-sm auto 0 auto
    padding-bottom: $margin-max
    &.loading
      opacity: 0.8
      input, textarea, button
        pointer-events: none
      .loader
        display: inline-flex
    &.sent
      text-align: center
      white-space: pre-wrap
      line-height: 1.9
      font-size: $font-md

    #form_error
      color: $color-red
      margin-bottom: $margin-md
    .steps
      position: relative
      border-bottom: 1px solid $color-light
      gap: $margin-xl
      $transition-delay: 450ms
      .step-tab
        box-sizing: border-box
        padding-bottom: 8px
        width: 150px
        transition: $transition-delay ease-in-out
        user-select: none
        cursor: pointer
        position: relative
        opacity: 0.5
        &:before
          position: absolute
          content: ''
          background-color: $color-primary
          bottom: -1px
          left: 0
          right: 0
          height: 1px
        &:after
          position: absolute
          content: ''
          background-color: $color-secondary
          bottom: -1px
          left: 50%
          width: 100%
          height: 1px
          max-width: 0
          transform: translateX(-50%)
          transition: $transition-delay ease-in-out
        &.active
          opacity: 1
          pointer-events: none
          &:before
            display: none
          &:after
            max-width: 500px

    .step
      display: none
      &.active
        display: block

    .input_group
      display: flex
      flex-direction: column
      color: $color-primary
      label
        min-width: 150px
        text-align: left
        position: relative
        &.required
          span
            position: relative
            &::after
              position: absolute
              content: '*'
              color: $color-red
              right: -10px
      input, textarea, select
        box-sizing: border-box
        width: 100%
        padding: $spacing-sm $spacing-md
        background-color: $color-light
        border: none
        border-radius: $radius-sm
        &:active, &:focus
          outline-color: $color-secondary
        &:invalid
          outline-color: $color-red
      textarea
        resize: vertical
    .help
      margin-top: 4px
      font-size: $font-sm

    .show-img
      appearance: none
      background: none
      border: none
      outline: none
      display: inline-flex
      color: inherit
      align-items: center
      cursor: pointer
      position: relative
      &:hover
        opacity: 0.85
      .image
        width: 11px
        margin-left: 4px
      .img-ref
        position: absolute
        inset: 0
        display: block
        width: 100%
        height: 100%
        opacity: 0


    .animated-btn
      padding: $spacing-sm 90px
      &.back
        background-color: rgba($color-primary, 0.5)
        &:before
          display: none

    .submit
      flex-wrap: wrap
      gap: $margin-md
      row-gap: $margin-sm

@media screen and (max-width: $breakpoints-md)
  body#order-page
    #order_form
      .submit
        .animated-btn
          width: 100%


@media screen and (max-width: $breakpoints-sm)
  body#order-page
    section
      &#landing
        .landing-content
          width: 180px
