@use 'assets/css/_normalize.scss'

@import 'assets/css/_variables.sass'
@import 'assets/css/_typography.sass'
@import 'assets/css/_helpers.sass'
@import 'assets/css/_buttons.sass'
@import 'assets/css/_loaders.sass'
@import 'assets/css/_animations.sass'
@import 'assets/css/_common.sass'

@import 'assets/css/pages/_home.sass'
@import 'assets/css/pages/_product.sass'
@import 'assets/css/pages/_contact.sass'
@import 'assets/css/pages/_shoplist.sass'
@import 'assets/css/pages/_modelart-history.sass'
@import 'assets/css/pages/_policy.sass'
@import 'assets/css/pages/_order.sass'

@import 'assets/css/sections/_header.sass'
@import 'assets/css/sections/_breadcrumbs.sass'
@import 'assets/css/sections/_footer.sass'
