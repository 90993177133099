body#policy
  padding-top: 140px
  & > .container
    & > .inner
      padding-bottom: 80px
      max-width: 80%
      margin: auto

  h1
    position: relative
    &::after
      position: absolute
      content: ''
      bottom: -8px
      left: 0
      width: 100%
      height: 1px
      // background-color: $color-secondary



@media screen and (max-width: $breakpoints-sm)
  body#policy
    padding-top: 100px
    .inner
      max-width: 90%
    section
      &:first-of-type
        margin-top: $margin-xl
      &:not(:first-of-type)
        margin-top: $margin-lg

@media screen and (max-width: $breakpoints-xs)
  body#policy
    .inner
      padding-bottom: 40px
      max-width: 100%
    section
      &:first-of-type
        margin-top: $margin-lg
      &:not(:first-of-type)
        margin-top: $margin-md
