html
  font-size: 16px
  scroll-behavior: smooth
  body
    font-family: 'Quicksand', 'Raleway', 'Zen Kaku Gothic New', Helvetica, sans-serif
    font-weight: 400
    color: $color-primary
    line-height: 1.6
    font-size: $font-rg

*::selection
  background-color: $color-secondary
  color: $color-light

a
  text-decoration: none
  color: inherit
  &:hover
    opacity: 0.85
ul
  margin: 0
  padding: 0
  list-style: none

svg
  path, polygon
    fill: currentColor

#landing
  position: relative
  overflow: hidden
  max-height: 100vh
  &::before
    position: fixed
    content: ''
    width: 0
    border-top: clamp(200px, 20vw, 500px) solid $color-white
    border-right: 70vw solid transparent
    z-index: 1

.section-title
  position: relative
  padding-bottom: 8px
  &::before, &::after
    position: absolute
    content: ''
    bottom: 0px
    transform: translateX(-50%)
    height: 1px
    left: 50%
  &::before
    width: clamp(280px, 50%, 650px)
    background-color: $color-light
  &::after
    width: clamp(200px, 25%, 200%)
    background-color: $color-secondary
    z-index: 30

#scroll-top
  position: fixed
  bottom: 60px
  right: $margin-md
  z-index: 100
  color: $color-light
  animation: bump 4s linear infinite alternate
  user-select: none

  &:hover
    color: $color-secondary
    .arrow svg path
      stroke: $color-secondary

  .label
    writing-mode: vertical-lr
  .arrow
    width: 12px
    margin-left: 4px
    transform: rotate(180deg)
    svg
      display: block
      width: 100%
      height: auto
      path
        stroke: $color-light

#gallery-modal
  position: fixed
  top: 0
  left: 0
  height: 100vh
  width: 100vw
  z-index: 999999
  color: $color-white
  justify-content: center
  align-items: center
  display: flex
  visibility: hidden
  &.opened
    visibility: visible
    .inner
      transform: scale(1)
      opacity: 1
      transition: 300ms ease-in-out
  .outer
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    background-color: rgba($color-primary, 0.8)
  .inner
    position: relative
    z-index: 9999
    width: clamp(300px, 75%, 1100px)
    height: clamp(420px, 85%, 1100px)
    display: flex
    justify-content: center
    align-items: center
    opacity: 0
    transform: scale(.25)
    img
      max-height: 100%
      max-width: 100%
      margin: auto
      object-fit: cover
      object-position: center
  #close-modal
    position: absolute
    top: 10vh
    right: 10vw
    width: 40px
    z-index: 99999
    appearance: none
    outline: none
    border: none
    background: transparent
    color: inherit
    margin: 0
    padding: 0
    cursor: pointer
    background-color: rgba($color-primary, 0.5)
    border-radius: 50%
    svg
      display: block
      width: 100%
      height: auto
      path
        fill: currentColor

@media screen and (max-width: $breakpoints-lg)
  html
    font-size: 14px

@media screen and (max-width: $breakpoints-sm)
  #landing
    &::before
      display: none

  #scroll-top
    right: $spacing-md

  #gallery-modal
    .inner
      #close-modal
        width: 32px
