// .text-jp
//   font-family: 'Zen Kaku Gothic New', Arial, Helvetica, sans-serif

.text-bold
  font-weight: 700

.text-left
  text-align: left
.text-right
  text-align: right
.text-center
  text-align: center

.text-vertical
  writing-mode: vertical-rl

.text-primary
  color: $color-primary
.text-secondary
  color: $color-secondary
.text-white
  color: $color-white

.font-xs
  font-size: $font-xs
.font-sm
  font-size: $font-sm
.font-rg
  font-size: $font-rg
.font-md
  font-size: $font-md
.font-lg
  font-size: $font-lg
.font-xl
  font-size: $font-xl
.font-xxl
  font-size: $font-xxl

h1
  font-size: $font-lg
h2
  font-size: $font-md

h1,h2,h3,h4,h5,h6
  font-weight: normal
  margin: 0
  line-height: 1.55
p
  line-height: 1.7



@media screen and (max-width: $breakpoints-sm)
  $font-lg: 1.5rem
  $font-xl: 2.5rem
  .font-lg
    font-size: $font-lg
  .font-xl
    font-size: $font-xl
