.breadcrumbs
  position: relative
  padding: 30px 0
  margin: auto
  z-index: 100
  background-color: $color-white
  .separator
    margin: 0 8px
  .breacrumb
    position: relative
    &:last-of-type
      color: $color-secondary
      pointer-events: none
    &::after
      content: ''
      position: absolute
      bottom: 0
      left: 0px
      width: 100%
      height: 2px
      background-color: $color-primary
      border-radius: $radius-xs
      transform: scale(0)
      transform-origin: left
      transition: 300ms ease-in-out
    &:hover
      color: $color-primary
      &::after
        transform: scale(1)

// @media screen and (max-width: $breakpoints-sm)
//   .breadcrumbs

