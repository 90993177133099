.loader
  $size: 20px
  display: inline-flex
  position: relative
  width: $size
  height: $size
  justify-content: center
  align-items: center

  & div
    $rad: 20px
    box-sizing: border-box
    display: block
    position: absolute
    width: $rad
    height: $rad
    margin: 8px
    border: 2px solid #fff
    border-radius: 50%
    animation: loader 1.4s cubic-bezier(0.5, 0, 0.5, 1) infinite
    border-color: #fff transparent transparent transparent

    &:nth-child(1)
      animation-delay: -0.45s
    &:nth-child(2)
      animation-delay: -0.3s
    &:nth-child(3)
      animation-delay: -0.15s

@keyframes loader
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

