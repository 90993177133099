$font-xs: 0.75rem // 12px
$font-sm: 0.875rem // 14px
$font-rg: 1rem // 16px
$font-md: 1.3125rem // 21px
$font-lg: 2rem // 32px
$font-xl: 2.5rem // 40px
$font-xxl: 3.125rem // 50px

$color-primary: #535453
$color-secondary: #a27825
$color-white: #fff
$color-light: #e9e8e8
$color-dark: #0c0d0d
$color-red: #912f3c
$color-green: #537a5a
$color-grey: #a1a3a5

$bg-primary: $color-white
$bg-secondary: rgba(212, 156, 142, 0.1)


$radius-xs: 2px
$radius-sm: 4px
$radius-md: 8px
$radius-lg: 16px
$radius-xl: 32px
$radius-round: 50%

$breakpoints-xs: 576px
$breakpoints-sm: 768px
$breakpoints-md: 992px
$breakpoints-lg: 1280px
$breakpoints-xl: 1400px

$margin-xs: 10px
$margin-sm: 20px
$margin-md: 30px
$margin-lg: 40px
$margin-xl: 50px
$margin-xxl: 60px
$margin-max: 100px

$spacing-xs: 10px
$spacing-sm: 12px
$spacing-md: 16px
$spacing-lg: 20px
$spacing-xl: 24px
$spacing-xxl: 28px

$shadow-xs: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1)
$shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0px 10px 15px -5px, rgba(0, 0, 0, 0.04) 0px 7px 7px -5px
$shadow-md: 0 1px 3px rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px
$shadow-lg: 0 1px 3px rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0px 28px 23px -7px, rgba(0, 0, 0, 0.04) 0px 12px 12px -7px
$shadow-xl: 0 1px 3px rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0px 36px 28px -7px, rgba(0, 0, 0, 0.04) 0px 17px 17px -7px

// Custom variables
$wide-container-width: 1920px
$container-width: 1280px
$filter-color: rgba(255,255,255,.25)
