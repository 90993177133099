.footer
  position: relative
  background-color: $color-white
  z-index: 10
  a.contact
    position: relative
    display: block
    width: 100%
    .background
      img
        display: block
        max-width: 100%
    .labels
      position: absolute
      top: 50%
      transform: translateY(-50%)
      right: 10%
      h2, h3
        // padding: 6px
        background-color: rgba(12, 13, 13, 0.5)
        padding: 2px 8px
      h3
        font-style: italic
      h2
        background-color: rgba(12,13,13,0.5)
        margin-left: $margin-xl

  footer
    background-color: $color-primary
    color: $color-light
    padding: 80px 0

    .footer-header
      padding-bottom: $spacing-sm
      border-bottom: 1px solid $color-light

      .brand
        display: block
        width: clamp(75px, 18vw, 320px)
        svg
          display: block
          width: 100%
          height: auto
          path, polygon
            fill: currentColor


      .sns
        gap: $spacing-md
        padding-right: $margin-xl
        span
          margin-right: $margin-md
        .sns-links
          gap: $spacing-md
        .sns-link
          height: 20px
          svg
            display: block
            height: 100%
            width: auto

    .footer-navigation
      gap: clamp(32px, 5vw, 120px)
      a
        gap: $spacing-md
        justify-content: space-between
        flex-wrap: wrap
    .copyright, .privacy
      display: block

@media screen and (max-width: $breakpoints-sm)
  .footer
    a.contact
      .background img
        height: 40vw
        object-fit: cover
        object-position: center
      .labels
        right: 10px
        h3
          font-size: 1.5rem
          text-align: right
          padding: 0
        h2
          padding: 4px
          font-size: 1rem
          margin-left: 10px
    footer
      margin-top: $margin-sm
      padding-top: 60px
      padding-bottom: 20px

      .footer-header
        flex-direction: column
        border: none
        margin-bottom: $margin-md
        .brand
          width: clamp(150px, 18vw, 320px)

        .sns
          margin-top: $margin-md
          gap: $spacing-sm
          padding-bottom: 6px
          padding-left: 0
          justify-content: flex-end
          span
            font-size: $font-rg
            margin-right: $margin-xs
          .sns-links
            gap: $spacing-xl
            justify-content: space-between
          .sns-link
            height: 22px

      .footer-navigation
        gap: $spacing-md
        display: grid
        grid-template-columns: 1fr 1fr 1fr
        margin-top: $margin-sm
        padding-left: 0
        .products
          margin-top: $margin-xs
        .column
          align-items: flex-start
        h4,
        a.row,
        a.link-jp
          padding: 0 4px
        a.row, a.link-jp
          position: relative
          display: inline-flex
          justify-content: flex-start
          &::after
            position: absolute
            content: ''
            bottom: -2px
            left: 0
            width: 100%
            height: 1px
            background-color: $color-light
            opacity: 0.2
          &:not(:last-child)
            margin-bottom: $spacing-sm
        .font-md
          font-size: $font-rg
        .link-jp
          font-size: $font-sm

      .copyright, .privacy
        font-size: $font-xs
      .privacy
        text-decoration: underline



@media screen and (max-width: $breakpoints-xs)
  .footer
    footer
      .footer-header
        .sns
          padding-left: $spacing-xl
          align-items: flex-start
          flex-direction: column

      .footer-navigation
        padding-left: $spacing-xl
        grid-template-columns: 1fr
