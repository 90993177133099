@keyframes rollin
  0%
    transform: translateX(100vw) rotate(360*2deg)
  100%
    transform: translateX(0) rotate(0deg)

@keyframes bump
  0%
    transform: translateY(-30px)
  100%
    transform: translateX(0)

@keyframes fadein
  0%
    opacity: 0
    transform: translateY(100px)
  80%
    opacity: 1
    transform: translateY(-25px)
  100%
    opacity: 1
    transform: translateY(0)

@keyframes grow
  0%
    transform-origin: top
    transform: scale(1,0.25)
  100%
    transform: scale(1,1)

.rollout
  transform: translateX(100vw) rotate(360*2deg)
.rollin
  transform: translateX(0) rotate(0deg)
.fadeout
  opacity: 0
  transform: translateY(60px)
.fadein
  opacity: 1
  transform: translateY(0)
.slideout
  opacity: 0
  transform: translateX(25%)
.slidein
  opacity: 1
  transform: translateX(0)

*[data-animation="fadein"], *[data-animation="slidein"]
  transition: all 800ms ease-in
