$container-padding: $spacing-sm
.wide-container, .container
  position: relative
  margin: auto
  box-sizing: border-box
  padding: 0 $container-padding

.wide-container
  max-width: $wide-container-width + ($container-padding * 2)
.container
  max-width: $container-width + ($container-padding * 2)

.bg-secondary
  background-color: $bg-secondary

.hidden
  display: none
.inline
  display: inline
.block
  display: block
.inline-flex
  display: inline-flex
.row
  display: flex
.column
  display: flex
  flex-direction: column
.wrap
  flex-wrap: wrap
.justify-start
  justify-content: flex-start
.justify-center
  justify-content: center
.justify-end
  justify-content: flex-end
.justify-between
  justify-content: space-between
.align-start
  align-items: flex-start
.align-center
  align-items: center
.align-end
  align-items: flex-end

.img-responsive-x
  display: block
  max-width: 100%
  height: auto

.img-responsive-y
  display: block
  max-height: 100%
  width: auto

.img-cover
  display: block
  width: 100%
  height: 100%
  object-fit: cover
  object-position: center

.img-contain
  display: block
  width: 100%
  height: 100%
  object-fit: contain
  object-position: center

.radius-xs
  border-radius: $radius-xs
.radius-sm
  border-radius: $radius-sm
.radius-md
  border-radius: $radius-md
.radius-lg
  border-radius: $radius-lg
.radius-xl
  border-radius: $radius-xl
.radius-round
  border-radius: $radius-round

.m-xs
  margin: $margin-xs
.m-sm
  margin: $margin-sm
.m-md
  margin: $margin-md
.m-lg
  margin: $margin-lg
.m-xl
  margin: $margin-xl
.m-xxl
  margin: $margin-xxl
.m-max
  margin: $margin-max

.mx-xs
  margin-left: $margin-xs
  margin-right: $margin-xs
.mx-sm
  margin-left: $margin-sm
  margin-right: $margin-sm
.mx-md
  margin-left: $margin-md
  margin-right: $margin-md
.mx-lg
  margin-left: $margin-lg
  margin-right: $margin-lg
.mx-xl
  margin-left: $margin-xl
  margin-right: $margin-xl
.mx-xxl
  margin-left: $margin-xxl
  margin-right: $margin-xxl
.mx-max
  margin-left: $margin-max
  margin-right: $margin-max

.my-xs
  margin-top: $margin-xs
  margin-bottom: $margin-xs
.my-sm
  margin-top: $margin-sm
  margin-bottom: $margin-sm
.my-md
  margin-top: $margin-md
  margin-bottom: $margin-md
.my-lg
  margin-top: $margin-lg
  margin-bottom: $margin-lg
.my-xl
  margin-top: $margin-xl
  margin-bottom: $margin-xl
.my-xxl
  margin-top: $margin-xxl
  margin-bottom: $margin-xxl
.my-max
  margin-top: $margin-max
  margin-bottom: $margin-max

.mt-xs
  margin-top: $margin-xs
.mt-sm
  margin-top: $margin-sm
.mt-md
  margin-top: $margin-md
.mt-lg
  margin-top: $margin-lg
.mt-xl
  margin-top: $margin-xl
.mt-xxl
  margin-top: $margin-xxl
.mt-max
  margin-top: $margin-max

.mb-xs
  margin-bottom: $margin-xs
.mb-sm
  margin-bottom: $margin-sm
.mb-md
  margin-bottom: $margin-md
.mb-lg
  margin-bottom: $margin-lg
.mb-xl
  margin-bottom: $margin-xl
.mb-xxl
  margin-bottom: $margin-xxl
.mb-max
  margin-bottom: $margin-max

.ml-xs
  margin-left: $margin-xs
.ml-sm
  margin-left: $margin-sm
.ml-md
  margin-left: $margin-md
.ml-lg
  margin-left: $margin-lg
.ml-xl
  margin-left: $margin-xl
.ml-xxl
  margin-left: $margin-xxl
.ml-max
  margin-left: $margin-max

.mr-xs
  margin-right: $margin-xs
.mr-sm
  margin-right: $margin-sm
.mr-md
  margin-right: $margin-md
.mr-lg
  margin-right: $margin-lg
.mr-xl
  margin-right: $margin-xl
.mr-xxl
  margin-right: $margin-xxl
.mr-max
  margin-right: $margin-max

@media screen and (max-width: $breakpoints-sm)
  .container
    padding: 0 $spacing-lg
@media screen and (max-width: $breakpoints-xs)
  .container
    padding: 0 $spacing-xs
