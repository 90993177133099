.burger {
  font-size: $font-sm;
  cursor: pointer;
  padding: 3em 1.5em; ///////////////// touch area size
  &.showHitArea {
    border: 1px solid olive;
  }
  & > div {
    width: 4em;
    height: 0.5em;
    border-radius: $radius-md;
    background: $color-secondary;
    &::before,
    &::after {
      transition: font-size 0s;
      content: "";
      display: block;
      position: absolute;
      width: 4em;
      height: 0.5em;
      background: $color-secondary;
      border-radius: $radius-md;
    }
    &::before {
      margin-top: -1.5em;
    }
    &::after {
      margin-top: 1.5em;
    }
  }
}

.burger-cross {
  transition: all 0.7s, font-size 0s;
  transition-timing-function: cubic-bezier(0.68, -0.35, 0.265, 1.35);
  & > div {
    transition: all 0.1s 0.3s, font-size 0s;
    &::before,
    &::after {
      transition: all 0.3s 0.2s, font-size 0s;
    }
  }
  &.toggled {
    transform: rotate(180deg);
    > div::before {
      transform: rotate(45deg) translate(1.1em, 1em);
    }
    > div {
      background: transparent;
    }
    > div::after {
      transform: rotate(-45deg) translate(1.1em, -1em);
    }
  }
}

.burger-arrow {
  transition: all 0.7s, font-size 0s;
  transition-timing-function: cubic-bezier(0.68, 0, 0.265, 1.25);
  & > div {
    transition: all 0.5s, font-size 0s;
    &::before,
    &::after {
      transition: all 0.4s 0.2s, font-size 0s;
      transform-origin: left top;
    }
  }
  &.toggled {
    transform: rotate(180deg);
    > div::before {
      width: 3em;
      transform: rotate(-45deg) translate(-1.4em, 1.1em);
    }
    > div::after {
      width: 3em;
      transform: rotate(45deg) translate(-1em, -1.2em);
    }
  }
}
