@import './_burger.scss'

header
  padding: 40px 0
  z-index: 10000
  position: fixed
  top: 0
  left: 0
  right: 0
  &.light
    nav .links .right
      color: $color-primary!important
  &.scrolled
    // background: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.4), rgba(255,255,255,0))
    background: rgba(255,255,255,0.75)

    nav .links #right-links
      color: $color-primary

  #toggle-nav, .burger-outer
    display: none

  nav
    gap: $spacing-xl
    .brand
      width: clamp(75px, 18vw, 320px)
      color: $color-primary
      svg, img
        display: block
        width: 100%
        height: auto
        path, polygon
          fill: currentColor
    .links
      flex: 1 0 auto
      margin-left: $margin-xl
      .left
        gap: $spacing-lg
      #right-links
        color: $color-light


      .products-links
        position: relative
        user-select: none
        z-index: 99999
        &:hover
          .inner
            max-height: 1000px
        h5
          font-size: $font-rg
        .inner
          position: absolute
          overflow: hidden
          top: 0
          left: 0
          width: 270px
          background-color: rgba(255,255,255,0.9)
          transition: 200ms ease-in-out
          transform: translateY(2rem)
          max-height: 0
          text-align: left
          .title
            padding: $spacing-xs
            font-weight: bold
            .link-jp
              font-size: $font-sm
              margin-left: $spacing-sm
              display: inline-flex
          .sub-sections
            max-height: 80vh
            overflow-y: scroll
          li.product
            padding: $spacing-xs $spacing-md
            font-size: 0.9rem
          a.sub-link
            position: relative
            padding: 4px
            &::after
              content: ''
              position: absolute
              bottom: 0
              left: 0
              width: 100%
              height: 2px
              background-color: $color-primary
              transform: scale(0)
              transition: 300ms ease-in-out
              transform-origin: right
            &.active
              color: $color-secondary
              &::after
                background-color: $color-secondary
            &:hover, &.active
              background-color: rgba(255,255,255,0.3)
              &::after
                transform: scale(1)

      .languages
        margin-right: $margin-xl
        .language
          user-select: none
          padding: 0 $spacing-xs
          &.active
            color: $color-secondary
            pointer-events: none
          &:hover
            opacity: 0.85

      .link
        position: relative
        display: block
        padding: 0
        height: 100%
        text-align: center

        &::after
          content: ''
          position: absolute
          bottom: 0
          left: 0
          width: 100%
          height: 2px
          background-color: $color-primary
          border-radius: $radius-md
          transform: scale(0)
          transition: 300ms ease-in-out
          transform-origin: right

        &.active
          color: $color-secondary
          &::after
            background-color: $color-secondary

        &:hover, &.active
          &::after
            transform: scale(1)

@media screen and (max-width: $breakpoints-sm)
  $burgerSize: 44px
  $transition-duration: 0.5s

  header
    position: absolute
    background: none
    padding: $margin-sm 0
    z-index: 200

    #toggle-nav
      position: absolute
      // left: -100%
      // top: -100%
      // z-index: 1000

      &:checked
        & ~ .links
          transform: scale(1)
          opacity: 1

    .burger-outer
      position: fixed
      top: 8px
      right: 8px
      padding: 0
      width: $burgerSize
      height: $burgerSize
      box-sizing: border-box
      display: flex
      justify-content: center
      align-items: center
      background-color: rgba(255,255,255,0.75)
      border-radius: $radius-md
      margin-right: 0
      margin-left: auto
      z-index: 999
      .burger-container
        transform: scale(0.55)

    nav
      .brand
        width: 120px
      .links
        z-index: 200
        margin-left: 0
        position: fixed
        top: 0
        bottom: 0
        right: 0
        left: 0
        transform: scale(0)
        opacity: 0
        background-color: $color-primary
        color: $color-light
        transition: $transition-duration, opacity 0.1s
        transform-origin: top right
        display: flex
        flex-direction: column
        justify-content: center
        align-items: flex-start
        .left
          flex-direction: column
          align-items: flex-start
          gap: $spacing-lg
        .right
          flex-direction: column-reverse
          margin-top: $margin-xs
          color: $color-light!important
          width: 100%
          a.link
            align-self: flex-start
          .languages
            margin-top: $margin-sm
            margin-right: 0
            align-self: center

        .products-links
          text-align: left
          cursor: inherit
          h5
            font-size: 1.2rem
            // margin-bottom: $spacing-xs
          .inner
            max-height: 1000px
            position: relative
            top: auto
            left: auto
            width: auto
            background: none
            transform: none
            .sub-section
              &.opened
                .title::after
                  transform: translateY(-50%) rotate(180deg)
                ul.products
                  max-height: 25vh
                  overflow-y: scroll

            .title
              padding: $spacing-lg $spacing-sm
              position: relative
              &::after
                position: absolute
                content: '▼'
                right: 0
                top: 50%
                transform: translateY(-50%)
                font-size: 0.7rem
                transition: 300ms ease-in-out
                transform-origin: center
            ul.products
              max-height: 0
              overflow: hidden
              padding-left: $spacing-xs
              transition: 300ms ease-in-out
            li.product
              padding: 8px $spacing-sm
            a.sub-link
              &.active, &:hover
                background: none
        .link, .sub-link
          padding-left: $margin-lg
          font-size: 1.15rem
          justify-content: center
          &:after
            bottom: -2px

      .sns
        align-self: center
        display: flex
        flex-direction: column
        margin-top: $margin-xs
        .sns-links
          margin-top: $spacing-md
          gap: $margin-sm
          .sns-link
            height: 18px
            svg
              display: block
              height: 100%
              width: auto

