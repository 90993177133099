html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

.text-bold {
  font-weight: 700;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-vertical {
  writing-mode: vertical-rl;
}

.text-primary {
  color: #535453;
}

.text-secondary {
  color: #a27825;
}

.text-white {
  color: #fff;
}

.font-xs {
  font-size: .75rem;
}

.font-sm {
  font-size: .875rem;
}

.font-rg {
  font-size: 1rem;
}

.font-md {
  font-size: 1.3125rem;
}

.font-lg {
  font-size: 2rem;
}

.font-xl {
  font-size: 2.5rem;
}

.font-xxl {
  font-size: 3.125rem;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.3125rem;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: normal;
  line-height: 1.55;
}

p {
  line-height: 1.7;
}

@media screen and (max-width: 768px) {
  .font-lg {
    font-size: 1.5rem;
  }

  .font-xl {
    font-size: 2.5rem;
  }
}

.wide-container, .container {
  box-sizing: border-box;
  margin: auto;
  padding: 0 12px;
  position: relative;
}

.wide-container {
  max-width: 1944px;
}

.container {
  max-width: 1304px;
}

.bg-secondary {
  background-color: #d49c8e1a;
}

.hidden {
  display: none;
}

.inline {
  display: inline;
}

.block {
  display: block;
}

.inline-flex {
  display: inline-flex;
}

.row {
  display: flex;
}

.column {
  flex-direction: column;
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.img-responsive-x {
  max-width: 100%;
  height: auto;
  display: block;
}

.img-responsive-y {
  max-height: 100%;
  width: auto;
  display: block;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}

.img-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  display: block;
}

.radius-xs {
  border-radius: 2px;
}

.radius-sm {
  border-radius: 4px;
}

.radius-md {
  border-radius: 8px;
}

.radius-lg {
  border-radius: 16px;
}

.radius-xl {
  border-radius: 32px;
}

.radius-round {
  border-radius: 50%;
}

.m-xs {
  margin: 10px;
}

.m-sm {
  margin: 20px;
}

.m-md {
  margin: 30px;
}

.m-lg {
  margin: 40px;
}

.m-xl {
  margin: 50px;
}

.m-xxl {
  margin: 60px;
}

.m-max {
  margin: 100px;
}

.mx-xs {
  margin-left: 10px;
  margin-right: 10px;
}

.mx-sm {
  margin-left: 20px;
  margin-right: 20px;
}

.mx-md {
  margin-left: 30px;
  margin-right: 30px;
}

.mx-lg {
  margin-left: 40px;
  margin-right: 40px;
}

.mx-xl {
  margin-left: 50px;
  margin-right: 50px;
}

.mx-xxl {
  margin-left: 60px;
  margin-right: 60px;
}

.mx-max {
  margin-left: 100px;
  margin-right: 100px;
}

.my-xs {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-sm {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-md {
  margin-top: 30px;
  margin-bottom: 30px;
}

.my-lg {
  margin-top: 40px;
  margin-bottom: 40px;
}

.my-xl {
  margin-top: 50px;
  margin-bottom: 50px;
}

.my-xxl {
  margin-top: 60px;
  margin-bottom: 60px;
}

.my-max {
  margin-top: 100px;
  margin-bottom: 100px;
}

.mt-xs {
  margin-top: 10px;
}

.mt-sm {
  margin-top: 20px;
}

.mt-md {
  margin-top: 30px;
}

.mt-lg {
  margin-top: 40px;
}

.mt-xl {
  margin-top: 50px;
}

.mt-xxl {
  margin-top: 60px;
}

.mt-max {
  margin-top: 100px;
}

.mb-xs {
  margin-bottom: 10px;
}

.mb-sm {
  margin-bottom: 20px;
}

.mb-md {
  margin-bottom: 30px;
}

.mb-lg {
  margin-bottom: 40px;
}

.mb-xl {
  margin-bottom: 50px;
}

.mb-xxl {
  margin-bottom: 60px;
}

.mb-max {
  margin-bottom: 100px;
}

.ml-xs {
  margin-left: 10px;
}

.ml-sm {
  margin-left: 20px;
}

.ml-md {
  margin-left: 30px;
}

.ml-lg {
  margin-left: 40px;
}

.ml-xl {
  margin-left: 50px;
}

.ml-xxl {
  margin-left: 60px;
}

.ml-max {
  margin-left: 100px;
}

.mr-xs {
  margin-right: 10px;
}

.mr-sm {
  margin-right: 20px;
}

.mr-md {
  margin-right: 30px;
}

.mr-lg {
  margin-right: 40px;
}

.mr-xl {
  margin-right: 50px;
}

.mr-xxl {
  margin-right: 60px;
}

.mr-max {
  margin-right: 100px;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 20px;
  }
}

@media screen and (max-width: 576px) {
  .container {
    padding: 0 10px;
  }
}

.animated-btn {
  appearance: none;
  box-sizing: border-box;
  cursor: pointer;
  color: #fff;
  background-color: #535453;
  border: none;
  border-radius: 4px;
  outline: none;
  transition: all .3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.animated-btn:before {
  content: "";
  width: 100%;
  height: 100%;
  max-height: 0;
  max-width: 0;
  background-color: #a27825;
  transition: all .3s ease-in-out;
  position: absolute;
  bottom: 0;
  right: 0;
}

.animated-btn:hover:before {
  max-height: 70px;
  max-width: 500px;
}

.animated-btn span {
  z-index: 2;
  width: 100%;
  justify-content: center;
  display: inline-flex;
  position: relative;
}

.animated-btn .loader {
  display: none;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.loader {
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.loader div {
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border: 2px solid #0000;
  border-top-color: #fff;
  border-radius: 50%;
  margin: 8px;
  animation: 1.4s cubic-bezier(.5, 0, .5, 1) infinite loader;
  display: block;
  position: absolute;
}

.loader div:nth-child(1) {
  animation-delay: -.45s;
}

.loader div:nth-child(2) {
  animation-delay: -.3s;
}

.loader div:nth-child(3) {
  animation-delay: -.15s;
}

@keyframes loader {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rollin {
  0% {
    transform: translateX(100vw)rotate(720deg);
  }

  100% {
    transform: translateX(0)rotate(0);
  }
}

@keyframes bump {
  0% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  80% {
    opacity: 1;
    transform: translateY(-25px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes grow {
  0% {
    transform-origin: top;
    transform: scale(1, .25);
  }

  100% {
    transform: scale(1);
  }
}

.rollout {
  transform: translateX(100vw)rotate(720deg);
}

.rollin {
  transform: translateX(0)rotate(0);
}

.fadeout {
  opacity: 0;
  transform: translateY(60px);
}

.fadein {
  opacity: 1;
  transform: translateY(0);
}

.slideout {
  opacity: 0;
  transform: translateX(25%);
}

.slidein {
  opacity: 1;
  transform: translateX(0);
}

[data-animation="fadein"], [data-animation="slidein"] {
  transition: all .8s ease-in;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

html body {
  color: #535453;
  font-family: Quicksand, Raleway, Zen Kaku Gothic New, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
}

::selection {
  color: #e9e8e8;
  background-color: #a27825;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  opacity: .85;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

svg path, svg polygon {
  fill: currentColor;
}

#landing {
  max-height: 100vh;
  position: relative;
  overflow: hidden;
}

#landing:before {
  content: "";
  width: 0;
  z-index: 1;
  border-top: clamp(200px, 20vw, 500px) solid #fff;
  border-right: 70vw solid #0000;
  position: fixed;
}

.section-title {
  padding-bottom: 8px;
  position: relative;
}

.section-title:before, .section-title:after {
  content: "";
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.section-title:before {
  width: clamp(280px, 50%, 650px);
  background-color: #e9e8e8;
}

.section-title:after {
  width: max(200px, 25%);
  z-index: 30;
  background-color: #a27825;
}

#scroll-top {
  z-index: 100;
  color: #e9e8e8;
  -webkit-user-select: none;
  user-select: none;
  animation: 4s linear infinite alternate bump;
  position: fixed;
  bottom: 60px;
  right: 30px;
}

#scroll-top:hover {
  color: #a27825;
}

#scroll-top:hover .arrow svg path {
  stroke: #a27825;
}

#scroll-top .label {
  writing-mode: vertical-lr;
}

#scroll-top .arrow {
  width: 12px;
  margin-left: 4px;
  transform: rotate(180deg);
}

#scroll-top .arrow svg {
  width: 100%;
  height: auto;
  display: block;
}

#scroll-top .arrow svg path {
  stroke: #e9e8e8;
}

#gallery-modal {
  height: 100vh;
  width: 100vw;
  z-index: 999999;
  color: #fff;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

#gallery-modal.opened {
  visibility: visible;
}

#gallery-modal.opened .inner {
  opacity: 1;
  transition: all .3s ease-in-out;
  transform: scale(1);
}

#gallery-modal .outer {
  background-color: #535453cc;
  position: absolute;
  inset: 0;
}

#gallery-modal .inner {
  z-index: 9999;
  width: clamp(300px, 75%, 1100px);
  height: clamp(420px, 85%, 1100px);
  opacity: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  transform: scale(.25);
}

#gallery-modal .inner img {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
  margin: auto;
}

#gallery-modal #close-modal {
  width: 40px;
  z-index: 99999;
  appearance: none;
  color: inherit;
  cursor: pointer;
  background: #53545380;
  border: none;
  border-radius: 50%;
  outline: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 10vh;
  right: 10vw;
}

#gallery-modal #close-modal svg {
  width: 100%;
  height: auto;
  display: block;
}

#gallery-modal #close-modal svg path {
  fill: currentColor;
}

@media screen and (max-width: 1280px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  #landing:before {
    display: none;
  }

  #scroll-top {
    right: 16px;
  }

  #gallery-modal .inner #close-modal {
    width: 32px;
  }
}

body#home-page section {
  z-index: 1;
  background-color: #fff;
  position: relative;
}

body#home-page section#landing {
  min-height: 100vh;
  overflow: hidden;
}

body#home-page section#landing video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

body#home-page section#landing video.scrolled {
  position: absolute;
}

body#home-page section#landing .galerna-logo {
  width: clamp(150px, 17vw, 300px);
  color: #fff;
  position: absolute;
  bottom: 60px;
  left: 6vw;
}

body#home-page section#landing .galerna-logo svg {
  width: 100%;
  height: auto;
  display: block;
}

body#home-page section#landing .inner {
  height: 100%;
  min-height: 100vh;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}

body#home-page section#landing .landing-content {
  max-width: clamp(300px, 45vw, 700px);
  padding-bottom: 60px;
}

body#home-page section#landing .scroll-down {
  z-index: 20;
  color: #e9e8e8;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  bottom: 10px;
  left: 30px;
}

body#home-page section#landing .scroll-down .label {
  transform-origin: 0;
  transform: rotate(-90deg);
}

body#home-page section#landing .scroll-down .arrow {
  width: 12px;
  margin-left: -4px;
}

body#home-page section#landing .scroll-down .arrow svg {
  width: 100%;
  height: auto;
  animation: 6s linear infinite alternate grow;
  display: block;
}

body#home-page section#landing .scroll-down .arrow svg path {
  stroke: #e9e8e8;
}

body#home-page section#new-models {
  padding-top: 50px;
}

body#home-page section#new-models .new-wheels {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 40px;
  display: grid;
}

body#home-page section#new-models article.new-wheel:nth-child(1) .image {
  transition-delay: .15s;
}

body#home-page section#new-models article.new-wheel:nth-child(2) .image {
  transition-delay: .3s;
}

body#home-page section#new-models article.new-wheel:nth-child(3) .image {
  transition-delay: .45s;
}

body#home-page section#new-models article.new-wheel:nth-child(4) .image {
  transition-delay: .6s;
}

body#home-page section#new-models .new-wheel .image {
  position: relative;
}

body#home-page section#new-models .new-wheel .image img {
  margin: auto;
  transition: transform 2s cubic-bezier(.55, -.55, .2, 2.25);
}

body#home-page section#new-models .new-wheel .image .logo {
  opacity: 0;
  background-color: #ffffffbf;
  padding: 0 1rem;
  animation: .3s ease-in-out 2.1s forwards fadein;
  position: absolute;
  bottom: 0;
  right: 0;
}

body#home-page section#new-models .new-wheel .image .logo.galerna {
  padding: 8px 14px;
}

body#home-page section#new-models .new-wheel .image .logo.galerna svg {
  height: 20px;
}

body#home-page section#new-models .new-wheel .image .logo img {
  height: 38px;
  width: auto;
}

body#home-page section#new-models .new-wheel .content {
  transition: opacity 1s ease-in .6s;
}

body#home-page section#new-models .new-wheel h3 {
  align-self: center;
}

body#home-page section#new-models .new-wheel .wheel-tag {
  color: #e9e8e8;
  background-color: #a27825;
  border-radius: 2px;
  align-self: center;
  padding: 2px 8px;
  font-size: .875rem;
}

body#home-page section#new-models .new-wheel .wheel-tag.invisible {
  visibility: hidden;
}

body#home-page section#lineup {
  padding-top: 100px;
}

body#home-page section#lineup section.sub-brand:nth-child(2n+1) .top-row {
  flex-direction: row-reverse;
}

body#home-page section#lineup section.sub-brand#shallen .sub-brand-image .image {
  background-image: url("shallen-top-image.3e67407e.jpg");
}

body#home-page section#lineup section.sub-brand#modelart .sub-brand-image .image {
  background-image: url("modelart-top-image.ffa939c1.jpg");
}

body#home-page section#lineup section.sub-brand#tracer .sub-brand-image .image {
  background-image: url("tracer-top-image.aa5ad742.jpg");
}

body#home-page section#lineup section.sub-brand#galerna .sub-brand-image .image {
  background-image: url("galerna-top-image.baf49994.jpg");
}

body#home-page section#lineup section.sub-brand .top-row {
  gap: 60px;
}

body#home-page section#lineup section.sub-brand .top-row .left, body#home-page section#lineup section.sub-brand .top-row .right {
  flex: 1;
}

body#home-page section#lineup section.sub-brand .top-row .right {
  padding: 100px 0;
}

body#home-page section#lineup section.sub-brand .top-row .sub-brand-image {
  max-width: clamp(250px, 100%, 850px);
  height: 100%;
}

body#home-page section#lineup section.sub-brand .top-row .sub-brand-image.border-left .image:before {
  border-top: clamp(80px, 6vw, 150px) solid #fff;
  border-bottom: clamp(80px, 6vw, 150px) solid #0000;
  border-left: clamp(150px, 15vw, 275px) solid #fff;
  border-right: clamp(150px, 15vw, 275px) solid #0000;
}

body#home-page section#lineup section.sub-brand .top-row .sub-brand-image.border-right .image:before {
  border-top: clamp(80px, 6vw, 150px) solid #fff;
  border-bottom: clamp(80px, 6vw, 150px) solid #0000;
  border-left: clamp(150px, 15vw, 275px) solid #0000;
  border-right: clamp(150px, 15vw, 275px) solid #fff;
  right: 0;
}

body#home-page section#lineup section.sub-brand .top-row .sub-brand-image .image {
  width: 100%;
  height: clamp(300px, 100%, 700px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto clamp(250px, 100%, 850px);
  background-attachment: fixed;
  position: relative;
  overflow: hidden;
}

body#home-page section#lineup section.sub-brand .top-row .sub-brand-image .image:before {
  content: "";
  width: 0;
  z-index: 1;
  position: absolute;
}

body#home-page section#lineup section.sub-brand .top-row .sub-brand-logo {
  max-width: clamp(300px, 20vw, 400px);
  margin: 40px auto 10px;
}

body#home-page section#lineup section.sub-brand .top-row .sub-brand-logo svg {
  width: 100%;
  height: auto;
  display: block;
}

body#home-page section#lineup section.sub-brand .top-row .sub-brand-logo svg path.accent {
  fill: #6d6e71;
}

body#home-page section#lineup section.sub-brand .top-row h3 {
  white-space: pre-line;
}

body#home-page section#lineup section.sub-brand .top-row .description {
  width: clamp(250px, 25vw, 450px);
  margin: auto;
}

body#home-page section#lineup section.sub-brand .wheels-grid {
  margin-top: 100px;
}

body#home-page section#lineup section.sub-brand .wheels-row {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 40px;
  display: grid;
}

body#home-page section#lineup section.sub-brand .wheel .image {
  position: relative;
}

body#home-page section#lineup section.sub-brand .wheel .image .wheel-img {
  transition: all .4s ease-in-out;
}

body#home-page section#lineup section.sub-brand .wheel .new {
  width: 65px;
  height: auto;
  position: absolute;
  bottom: 0;
  right: 0;
}

body#home-page section#lineup section.sub-brand .wheel h3 {
  white-space: pre-line;
}

body#home-page section#lineup section.sub-brand .wheel .sizes {
  gap: 10px 20px;
}

body#home-page section#lineup section.sub-brand .banner {
  display: block;
}

body#home-page section#links {
  padding-top: 100px;
  padding-bottom: 50px;
}

body#home-page section#links .container {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px 8px;
  display: grid;
}

body#home-page section#links .container:nth-child(2n) {
  grid-template-columns: 1fr 1fr;
}

body#home-page section#links .container a.link {
  position: relative;
}

body#home-page section#links .container a.link:hover .image img {
  transform: scale(1);
}

body#home-page section#links .container a.link .image {
  width: 80%;
  height: 200px;
  margin-left: auto;
  overflow: hidden;
}

body#home-page section#links .container a.link .image img {
  transition: all .3s ease-in-out;
  transform: scale(1.05);
}

body#home-page section#links .container a.link .title {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

body#home-page section#links .container a.link .title > div {
  background-color: #0c0d0d80;
  padding: 2px 8px;
}

body#home-page section#links .container a.link .title .sub-label {
  font-size: 1.25rem;
  font-style: italic;
}

body#home-page section#links .container a.link .title .main-label {
  margin-left: 30px;
}

body#home-page [data-animation="fadein"] {
  transition: all .8s ease-in;
}

@media screen and (max-width: 768px) {
  body#home-page section#landing .landing-content {
    max-width: clamp(300px, 70vw, 700px);
    padding-right: 0;
  }

  body#home-page section#landing .landing-content h2 {
    font-size: 1.15rem;
  }

  body#home-page section#landing .landing-content p {
    font-size: .875rem;
  }

  body#home-page section#landing .galerna-logo {
    bottom: 20px;
    left: 50px;
  }

  body#home-page section#landing .scroll-down {
    bottom: 0;
    left: 20px;
  }

  body#home-page section#new-models .new-wheels {
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    margin-top: 60px;
    overflow: hidden;
  }

  body#home-page section#new-models .new-wheels .new-wheel .image {
    max-width: clamp(150px, 80%, 340px);
    margin: auto;
  }

  body#home-page section#new-models .new-wheels .new-wheel .content {
    margin-top: 30px;
  }

  body#home-page section#new-models .new-wheels .new-wheel .content p {
    max-width: 70%;
    margin: 30px auto;
  }

  body#home-page section#new-models .new-wheels .new-wheel .wheel-tag {
    margin-top: 10px;
  }

  body#home-page section#lineup section.sub-brand {
    margin-top: 60px;
  }

  body#home-page section#lineup section.sub-brand .top-row, body#home-page section#lineup section.sub-brand .top-row:nth-child(2n+1) {
    flex-direction: column;
    gap: 0;
  }

  body#home-page section#lineup section.sub-brand .top-row .right {
    padding: 50px 0;
  }

  body#home-page section#lineup section.sub-brand .top-row .sub-brand-image.border-left .image:before {
    border-top: clamp(40px, 10vw, 100px) solid #fff;
    border-bottom: clamp(40px, 10vw, 100px) solid #0000;
    border-left: clamp(75px, 15vw, 150px) solid #fff;
    border-right: clamp(75px, 15vw, 150px) solid #0000;
  }

  body#home-page section#lineup section.sub-brand .top-row .sub-brand-image.border-right .image:before {
    border-top: clamp(40px, 10vw, 100px) solid #fff;
    border-bottom: clamp(40px, 10vw, 100px) solid #0000;
    border-left: clamp(75px, 15vw, 150px) solid #0000;
    border-right: clamp(75px, 15vw, 150px) solid #fff;
    right: 0;
  }

  body#home-page section#lineup section.sub-brand .wheels-grid {
    margin-top: 40px;
  }

  body#home-page section#lineup section.sub-brand .wheels-grid .wheels-row {
    grid-template-columns: 1fr 1fr;
  }

  body#home-page section#lineup section.sub-brand .wheels-grid .wheels-row .image {
    max-width: clamp(150px, 80%, 340px);
    margin: auto;
  }

  body#home-page section#links {
    padding-top: 60px;
    padding-bottom: 30px;
  }

  body#home-page section#links .container {
    gap: 12px;
    grid-template-columns: 1fr !important;
  }

  body#home-page section#links .container a.link .image {
    width: 100%;
    height: 40vw;
  }

  body#home-page section#links .container a.link .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  body#home-page section#links .container a.link .title {
    color: #e9e8e8;
    inset: auto 6px 6px auto;
    transform: none;
  }

  body#home-page section#links .container a.link .main-label {
    font-size: 1rem;
  }
}

@media screen and (max-width: 576px) {
  body#home-page section#landing .landing-content {
    width: 80%;
    max-width: none;
    margin-left: auto;
    margin-right: auto;
  }

  body#home-page section#new-models .new-wheels, body#home-page section#lineup section.sub-brand .wheels-grid .wheels-row {
    grid-template-columns: 1fr;
  }
}

body#product-page .image-notice {
  padding-right: 20px;
}

body#product-page section {
  z-index: 1;
  background-color: #fff;
  position: relative;
}

body#product-page section#landing {
  height: 65vh;
}

body#product-page section#landing > .wide-container {
  height: 100%;
}

body#product-page section#landing #landing-bg {
  height: 65vh;
  object-fit: cover;
  z-index: 0;
  display: block;
  position: fixed;
  top: 0;
  left: max(0px, 50vw - 966px);
  right: max(0px, 50vw - 966px);
}

body#product-page section#landing #landing-bg .image {
  height: 100%;
}

body#product-page section#landing #landing-bg img {
  max-height: 65vh;
}

body#product-page section#landing #landing-bg.scrolled {
  position: absolute;
}

body#product-page section#landing .logo {
  width: clamp(150px, 16vw, 320px);
  z-index: 10;
  position: absolute;
  top: clamp(80px, 8vw, 150px);
  left: 17vw;
}

body#product-page section#landing .landing-content {
  color: #e9e8e8;
  z-index: 10;
  background-color: #0c0d0d80;
  padding: 20px 30px;
  position: absolute;
  bottom: 10%;
  right: 20%;
}

body#product-page section#landing .landing-content.lx {
  background-color: #0c0d0db3;
  padding: 30px;
  bottom: 2%;
  left: 10%;
  right: auto;
}

body#product-page section#product, body#product-page section#detail, body#product-page section#lineup, body#product-page section#gallery, body#product-page section#video {
  padding-top: 60px;
}

body#product-page section#product .main-row {
  gap: 60px;
}

body#product-page section#product .main-row .left {
  flex: 1;
}

body#product-page section#product .main-row .right {
  flex: 2;
  padding-top: 30px;
}

body#product-page section#product .main-row .right.bg-primary .image {
  background-color: #535453;
}

body#product-page section#product .main-row .right .image {
  width: clamp(260px, 19vw, 360px);
  margin: auto;
}

body#product-page section#product .main-row .right .image p {
  max-width: 80%;
  margin: auto;
}

body#product-page section#detail .row:nth-child(2n+1) {
  flex-direction: row-reverse;
}

body#product-page section#detail .row .left {
  flex: 7;
}

body#product-page section#detail .row .left p {
  width: clamp(280px, 35vw, 640px);
  text-align: left;
  margin: auto;
}

body#product-page section#detail .row .right {
  flex: 6;
}

body#product-page section#detail .image {
  width: 100%;
  height: 450px;
  position: relative;
  overflow: hidden;
}

body#product-page section#detail .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
}

body#product-page section#full-width {
  background-color: #fff;
  padding-top: 30px;
  position: relative;
  overflow: hidden;
}

body#product-page section#full-width .wide-container {
  height: clamp(100px, 33vw, 637px);
  width: clamp(300px, 100vw, 1920px);
  position: relative;
  overflow: hidden;
}

body#product-page section#full-width .wide-container .image {
  position: absolute;
  inset: 0;
}

body#product-page section#lineup .red {
  color: #912f3c;
}

body#product-page section#lineup .grey {
  color: #a1a3a5;
}

body#product-page section#lineup .lineup-grid {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  display: grid;
}

body#product-page section#lineup .lineup-grid .wheel .image {
  width: 80%;
  margin: auto;
}

body#product-page section#lineup .order-link {
  width: clamp(200px, 50%, 400px);
  padding: 12px 24px;
  font-size: 1.3125rem;
}

body#product-page section#lineup .order-link:hover:before {
  max-height: 70px;
  max-width: 400px;
}

body#product-page section#lineup .banner {
  display: block;
}

body#product-page section#lineup .banner img {
  margin: auto;
}

body#product-page section#lineup .specs {
  overflow-x: auto;
}

body#product-page section#lineup .specs table, body#product-page section#lineup .specs th, body#product-page section#lineup .specs td {
  white-space: nowrap;
  border: 1px solid #e9e8e8;
}

body#product-page section#lineup .specs table {
  min-width: 100%;
  border-collapse: collapse;
  position: relative;
}

body#product-page section#lineup .specs table thead {
  color: #e9e8e8;
  background-color: #535453;
}

body#product-page section#lineup .specs table th, body#product-page section#lineup .specs table td {
  text-align: center;
  padding: 4px 12px;
}

body#product-page section#lineup .specs table th.no-bg, body#product-page section#lineup .specs table td.no-bg {
  background: #fff;
}

body#product-page section#lineup .specs table th {
  z-index: 2147483647;
  position: sticky;
  top: 0;
}

body#product-page section#lineup .specs table td.comment {
  text-align: left;
}

body#product-page section#lineup .specs table .accent {
  color: #fff;
  background-color: #a27825;
}

body#product-page section#lineup .specs table .greyed {
  color: #a1a3a5;
}

body#product-page section#lineup .info .row {
  gap: 10px 30px;
}

body#product-page section#lineup .info .info-item {
  line-height: 1.9;
}

body#product-page section#lineup .info .info-item:before {
  margin-right: 10px;
}

body#product-page section#lineup .info .info-item.double > div {
  width: calc(100% - 30px);
}

body#product-page section#lineup .info .square:before {
  content: "■";
}

body#product-page section#lineup .info .circle:before {
  content: "●";
}

body#product-page section#lineup .info .notice:before {
  content: "※";
}

body#product-page section#gallery {
  padding-bottom: 60px;
}

body#product-page section#gallery .gallery-grid {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
  display: grid;
}

body#product-page section#gallery .gallery-grid .image {
  cursor: pointer;
  max-height: 280px;
  overflow: hidden;
}

body#product-page section#gallery .gallery-grid .image:hover {
  opacity: .85;
}

body#product-page section#gallery .gallery-grid .image:hover img {
  transform: scale(1);
}

body#product-page section#gallery .gallery-grid .image img {
  transition: all .3s ease-in-out;
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
  body#product-page section#landing, body#product-page section#landing #landing-bg {
    height: 50vh;
  }

  body#product-page section#landing #landing-bg img {
    max-height: 50vh;
  }

  body#product-page section#landing .wide-container {
    padding: 0;
  }

  body#product-page section#landing .wide-container > .image {
    height: 100%;
  }

  body#product-page section#landing .logo {
    display: none;
  }

  body#product-page section#landing .landing-content {
    padding: 16px 10px;
    font-size: .875rem;
    bottom: 8px;
    left: 0;
    right: 0;
  }

  body#product-page section#landing .landing-content br {
    display: none;
  }

  body#product-page section#product, body#product-page section#detail, body#product-page section#lineup, body#product-page section#gallery, body#product-page section#video {
    padding-top: 40px;
  }

  body#product-page section#product .main-row {
    flex-direction: column;
    gap: 30px;
  }

  body#product-page section#product .main-row .left .image {
    max-width: 80%;
    margin: auto;
  }

  body#product-page section#product .main-row .right {
    flex: 1;
    padding-top: 0;
  }

  body#product-page section#product .main-row .right h2 {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  body#product-page section#detail {
    margin-top: 30px;
  }

  body#product-page section#detail .detail-row {
    margin-top: 30px;
    flex-direction: column-reverse !important;
  }

  body#product-page section#detail .detail-row .left {
    margin-top: 30px;
  }

  body#product-page section#detail .detail-row .left h3 {
    margin-bottom: .875rem;
  }

  body#product-page section#lineup {
    margin-top: 30px;
  }

  body#product-page section#lineup .lineup-grid {
    gap: 10px;
  }

  body#product-page section#gallery .gallery-grid {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-top: 30px;
  }

  body#product-page section#video {
    margin-top: 30px;
  }
}

@media screen and (max-width: 576px) {
  body#product-page section#product, body#product-page section#detail, body#product-page section#lineup, body#product-page section#gallery, body#product-page section#video {
    padding-top: 20px;
  }

  body#product-page section#lineup .lineup-grid {
    grid-template-columns: 1fr;
  }

  body#product-page section#gallery {
    padding-bottom: 30px;
  }
}

body#contact-page section {
  z-index: 10;
  background-color: #fff;
  position: relative;
}

body#contact-page section#landing {
  height: 65vh;
}

body#contact-page section#landing > .wide-container {
  height: 100%;
}

body#contact-page section#landing #landing-bg {
  height: 65vh;
  object-fit: cover;
  z-index: 0;
  display: block;
  position: fixed;
  top: 0;
  left: max(0px, 50vw - 966px);
  right: max(0px, 50vw - 966px);
}

body#contact-page section#landing #landing-bg .image {
  height: 100%;
}

body#contact-page section#landing #landing-bg img {
  max-height: 65vh;
}

body#contact-page section#landing #landing-bg.scrolled {
  position: absolute;
}

body#contact-page section#landing .landing-content {
  color: #e9e8e8;
  z-index: 10;
  width: 160px;
  background-color: #0c0d0d80;
  padding: 20px 30px;
  position: absolute;
  bottom: 10%;
  right: 20%;
}

body#contact-page section#landing .landing-content .font-lg {
  text-align: left;
}

body#contact-page section#landing .landing-content .font-md {
  text-align: right;
}

body#contact-page section#contact .form_title h4 {
  border-bottom: 1px solid #a27825;
  padding: 4px 16px;
}

body#contact-page section#contact form#contact_form {
  max-width: clamp(300px, 60vw, 650px);
  text-align: center;
  color: #537a5a;
  margin: 50px auto 0;
  padding-bottom: 100px;
}

body#contact-page section#contact form#contact_form.loading {
  opacity: .8;
}

body#contact-page section#contact form#contact_form.loading input, body#contact-page section#contact form#contact_form.loading textarea, body#contact-page section#contact form#contact_form.loading button {
  pointer-events: none;
}

body#contact-page section#contact form#contact_form.loading .loader {
  display: inline-flex;
}

body#contact-page section#contact form#contact_form #form_error {
  color: #912f3c;
}

body#contact-page section#contact form#contact_form .input_group {
  color: #535453;
  flex-direction: column;
  display: flex;
}

body#contact-page section#contact form#contact_form .input_group label {
  min-width: 150px;
  text-align: left;
}

body#contact-page section#contact form#contact_form .input_group input, body#contact-page section#contact form#contact_form .input_group textarea {
  width: 100%;
  background-color: #e9e8e8;
  border: none;
  border-radius: 4px;
  padding: 12px 16px;
}

body#contact-page section#contact form#contact_form .input_group input:active, body#contact-page section#contact form#contact_form .input_group input:focus, body#contact-page section#contact form#contact_form .input_group textarea:active, body#contact-page section#contact form#contact_form .input_group textarea:focus {
  outline-color: #a27825;
}

body#contact-page section#contact form#contact_form .input_group textarea {
  resize: vertical;
}

body#contact-page section#contact form#contact_form button.animated-btn {
  width: 175px;
  height: 42px;
}

body#contact-page section#contact form#contact_form button.animated-btn:hover:before {
  max-height: 100px;
  max-width: 400px;
}

@media screen and (max-width: 768px) {
  body#contact-page section#landing .landing-content {
    width: 160px;
  }

  body#contact-page section#contact p.text, body#contact-page section#contact .contact_hours, body#contact-page section#contact .form_title, body#contact-page section#contact form#contact_form, body#contact-page section#contact .submit {
    margin-top: 40px;
  }

  body#contact-page section#contact form#contact_form .input_group label {
    min-width: 130px;
  }
}

@media screen and (max-width: 576px) {
  body#contact-page section#contact p.text, body#contact-page section#contact .contact_hours, body#contact-page section#contact .form_title, body#contact-page section#contact form#contact_form, body#contact-page section#contact .submit {
    margin-top: 30px;
  }

  body#contact-page section#contact form#contact_form {
    padding-bottom: 50px;
  }

  body#contact-page section#contact form#contact_form .input_group {
    flex-direction: column;
    align-items: flex-start;
  }

  body#contact-page section#contact form#contact_form .input_group label {
    min-width: 130px;
  }

  body#contact-page section#contact form#contact_form .input_group input, body#contact-page section#contact form#contact_form .input_group textarea {
    width: auto;
    align-self: stretch;
  }
}

body#shoplist-page section {
  z-index: 10;
  background-color: #fff;
  position: relative;
}

body#shoplist-page section#landing {
  height: 65vh;
}

body#shoplist-page section#landing > .wide-container {
  height: 100%;
}

body#shoplist-page section#landing #landing-bg {
  width: 100%;
  height: 65vh;
  object-fit: cover;
  z-index: 0;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

body#shoplist-page section#landing #landing-bg .image {
  height: 100%;
}

body#shoplist-page section#landing #landing-bg img {
  max-height: 65vh;
}

body#shoplist-page section#landing #landing-bg.scrolled {
  position: absolute;
}

body#shoplist-page section#landing .landing-content {
  color: #e9e8e8;
  z-index: 10;
  width: 360px;
  box-sizing: border-box;
  background-color: #0c0d0d80;
  padding: 20px 30px;
  position: absolute;
  bottom: 10%;
  left: 20%;
}

body#shoplist-page section#landing .landing-content .font-lg {
  text-align: left;
}

body#shoplist-page section#landing .landing-content .font-md {
  text-align: right;
}

body#shoplist-page section#shops {
  padding-bottom: 100px;
}

body#shoplist-page section#shops .region-links {
  gap: 20px;
}

body#shoplist-page section#shops .region-links .region-link {
  color: #fff;
  background-color: #535453;
  border-radius: 4px;
  padding: 2px 12px;
}

body#shoplist-page section#shops .map {
  width: 100%;
  height: 500px;
  filter: grayscale(40%);
  overflow: hidden;
}

body#shoplist-page section#shops .map iframe {
  width: 100%;
  height: 560px;
  pointer-events: none;
  border: none;
  position: relative;
  top: -60px;
}

body#shoplist-page section#shops .region {
  color: #e9e8e8;
  -webkit-user-select: none;
  user-select: none;
  background-color: #535453;
  border-radius: 4px;
  padding: 4px 24px;
  display: inline-flex;
}

body#shoplist-page section#shops .shops-table {
  overflow-x: scroll;
}

body#shoplist-page section#shops .shops-table table {
  width: 100%;
  border-collapse: collapse;
}

body#shoplist-page section#shops .shops-table table th {
  color: #a27825;
  text-align: left;
  background-color: #e9e8e8;
  position: relative;
}

body#shoplist-page section#shops .shops-table table th:first-of-type:before {
  content: "";
  border-top: 20px solid #fff;
  border-right: 40px solid #0000;
  border-radius: 0 0 0 4px;
  position: absolute;
  top: 0;
  left: -2px;
}

body#shoplist-page section#shops .shops-table table th:last-of-type {
  border-radius: 0 4px 4px 0;
}

body#shoplist-page section#shops .shops-table table th, body#shoplist-page section#shops .shops-table table td {
  padding: 12px 30px;
}

body#shoplist-page section#shops .shops-table table tbody tr:nth-child(2n) {
  background-color: #e9e8e84d;
}

body#shoplist-page section#shops .shops-table table .name {
  white-space: nowrap;
  text-decoration: underline;
}

body#shoplist-page section#shops .shops-table table .phone {
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  body#shoplist-page section#landing .landing-content {
    width: 280px;
  }

  body#shoplist-page section#shops .map {
    height: 400px;
  }

  body#shoplist-page section#shops .map iframe {
    height: 460px;
    top: -60px;
  }

  body#shoplist-page section#shops .shops-table table th:first-of-type:before {
    border-top: 14px solid #fff;
    border-right: 28px solid #0000;
  }

  body#shoplist-page section#shops .shops-table table th, body#shoplist-page section#shops .shops-table table td {
    padding: 10px;
  }
}

@media screen and (max-width: 576px) {
  body#shoplist-page section#landing .landing-content {
    width: 260px;
    left: 10%;
  }

  body#shoplist-page section#shops .region-links {
    flex-wrap: wrap;
    gap: 10px;
  }

  body#shoplist-page section#shops .map {
    height: 300px;
    margin: 30px 0;
  }

  body#shoplist-page section#shops .map iframe {
    height: 360px;
    top: -60px;
  }

  body#shoplist-page section#shops .container.mt-max {
    margin-top: 30px;
  }

  body#shoplist-page section#shops .shops-table table .name {
    white-space: normal;
  }
}

body#history-page main {
  background-color: #fff;
}

body#history-page section {
  z-index: 10;
  background-color: #fff;
  position: relative;
}

body#history-page section#landing {
  height: 65vh;
}

body#history-page section#landing > .wide-container {
  height: 100%;
}

body#history-page section#landing #landing-bg {
  height: 65vh;
  object-fit: cover;
  z-index: 0;
  display: block;
  position: fixed;
  top: 0;
  left: max(0px, 50vw - 966px);
  right: max(0px, 50vw - 966px);
}

body#history-page section#landing #landing-bg .slider, body#history-page section#landing #landing-bg .slide, body#history-page section#landing #landing-bg .image {
  height: 65vh;
}

body#history-page section#landing #landing-bg img {
  max-height: 65vh;
}

body#history-page section#landing #landing-bg.scrolled {
  position: absolute;
}

body#history-page section#landing .landing-content {
  color: #e9e8e8;
  z-index: 10;
  width: 360px;
  box-sizing: border-box;
  background-color: #0c0d0d80;
  padding: 20px 30px;
  position: absolute;
  bottom: 10%;
  left: 20%;
}

body#history-page section#landing .landing-content .font-lg {
  text-align: left;
}

body#history-page section#landing .landing-content .font-md {
  text-align: right;
}

body#history-page section#origin > .row {
  gap: 50px;
}

body#history-page section#origin > .row .visual {
  width: clamp(200px, 42%, 540px);
}

body#history-page section#origin > .row .visual .ame-logo {
  width: 300px;
}

body#history-page section#origin > .row .visual .modelart-logo {
  width: 300px;
  margin-top: 12px;
  margin-left: auto;
  margin-right: 16px;
}

body#history-page section#origin > .row .visual .first-wheel {
  max-width: 230px;
}

body#history-page section#origin > .row .content {
  width: 58%;
}

body#history-page section#choice .row {
  gap: 60px;
}

body#history-page section#choice .left, body#history-page section#reason .left {
  width: clamp(300px, 20%, 540px);
}

body#history-page section#choice .right, body#history-page section#reason .right {
  width: 80%;
}

body#history-page section#choice p, body#history-page section#reason p {
  margin-top: 0;
}

body#history-page section#reason .lineup {
  gap: 12px;
}

body#history-page section#reason .lineup .wheel {
  cursor: pointer;
  position: relative;
}

body#history-page section#reason .lineup .wheel:hover .description {
  display: block;
}

body#history-page section#reason .lineup .wheel .description {
  white-space: nowrap;
  color: #fff;
  background-color: #535453;
  border-radius: 4px;
  padding: 4px 10px;
  font-size: .75rem;
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -120%);
}

body#history-page section#reason .lineup .wheel .image {
  width: 70px;
}

body#history-page section#bottom-banner {
  padding-bottom: 100px;
}

body#history-page .tns-outer, body#history-page .tns-ovh, body#history-page .tns-inner, body#history-page .slider, body#history-page .slide, body#history-page .slide .image {
  height: 100%;
}

body#history-page .main-container {
  z-index: 100;
  background-color: #fff;
  position: relative;
}

@media screen and (max-width: 768px) {
  body#history-page section#landing .landing-content {
    width: 280px;
  }

  body#history-page section#origin, body#history-page section#choice, body#history-page section#reason, body#history-page section#bottom-banner {
    margin-top: 60px;
  }
}

@media screen and (max-width: 576px) {
  body#history-page section#landing .landing-content {
    width: 260px;
    left: 10%;
  }

  body#history-page section#origin, body#history-page section#choice, body#history-page section#reason, body#history-page section#bottom-banner {
    margin-top: 50px;
  }

  body#history-page section#origin > .row {
    flex-direction: column;
    gap: 30px;
  }

  body#history-page section#origin > .row.mt-xxl {
    margin-top: 40px;
  }

  body#history-page section#origin > .row .visual {
    width: 100%;
  }

  body#history-page section#origin > .row .visual .ame-logo {
    width: clamp(220px, 70%, 450px);
  }

  body#history-page section#origin > .row .visual .modelart-logo {
    width: clamp(240px, 75%, 450px);
    margin-top: 20px;
  }

  body#history-page section#origin > .row .visual .wheel {
    justify-content: center;
  }

  body#history-page section#origin > .row .visual .wheel .inner {
    max-width: 60%;
    margin: auto;
  }

  body#history-page section#origin > .row .content {
    width: 100%;
  }

  body#history-page section#origin > .row .content h4 {
    margin-top: 0;
    font-size: 1.5rem;
  }

  body#history-page section#choice > .row, body#history-page section#reason > .row {
    flex-direction: column;
    gap: 30px;
  }

  body#history-page section#choice > .row .left, body#history-page section#reason > .row .left, body#history-page section#choice > .row .right, body#history-page section#reason > .row .right {
    width: 100%;
  }

  body#history-page section#reason #mid-slider {
    height: clamp(150px, 56vw, 400px);
  }

  body#history-page section#reason .lineup {
    flex-flow: wrap;
    gap: 16px;
  }

  body#history-page section#bottom-banner {
    padding-bottom: 50px;
  }

  body#history-page section#bottom-banner #bottom-slider {
    height: clamp(150px, 56vw, 400px);
  }
}

body#policy {
  padding-top: 140px;
}

body#policy > .container > .inner {
  max-width: 80%;
  margin: auto;
  padding-bottom: 80px;
}

body#policy h1 {
  position: relative;
}

body#policy h1:after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: -8px;
  left: 0;
}

@media screen and (max-width: 768px) {
  body#policy {
    padding-top: 100px;
  }

  body#policy .inner {
    max-width: 90%;
  }

  body#policy section:first-of-type {
    margin-top: 50px;
  }

  body#policy section:not(:first-of-type) {
    margin-top: 40px;
  }
}

@media screen and (max-width: 576px) {
  body#policy .inner {
    max-width: 100%;
    padding-bottom: 40px;
  }

  body#policy section:first-of-type {
    margin-top: 40px;
  }

  body#policy section:not(:first-of-type) {
    margin-top: 30px;
  }
}

body#order-page section {
  z-index: 10;
  background-color: #fff;
  position: relative;
}

body#order-page section#landing {
  height: 65vh;
}

body#order-page section#landing > .wide-container {
  height: 100%;
}

body#order-page section#landing #landing-bg {
  height: 65vh;
  object-fit: cover;
  z-index: 0;
  display: block;
  position: fixed;
  top: 0;
  left: max(0px, 50vw - 966px);
  right: max(0px, 50vw - 966px);
}

body#order-page section#landing #landing-bg .image {
  height: 100%;
}

body#order-page section#landing #landing-bg img {
  max-height: 65vh;
}

body#order-page section#landing #landing-bg.scrolled {
  position: absolute;
}

body#order-page section#landing .landing-content {
  color: #e9e8e8;
  z-index: 10;
  width: 260px;
  background-color: #0c0d0d80;
  padding: 20px 30px;
  position: absolute;
  bottom: 10%;
  right: 20%;
}

body#order-page section#landing .landing-content .font-lg {
  text-align: left;
}

body#order-page section#landing .landing-content .font-md {
  text-align: right;
}

body#order-page .flow {
  max-width: 800px;
  margin: 50px auto;
}

body#order-page #order_form {
  width: clamp(300px, 50vw, 620px);
  margin: 20px auto 0;
  padding-bottom: 100px;
}

body#order-page #order_form.loading {
  opacity: .8;
}

body#order-page #order_form.loading input, body#order-page #order_form.loading textarea, body#order-page #order_form.loading button {
  pointer-events: none;
}

body#order-page #order_form.loading .loader {
  display: inline-flex;
}

body#order-page #order_form.sent {
  text-align: center;
  white-space: pre-wrap;
  font-size: 1.3125rem;
  line-height: 1.9;
}

body#order-page #order_form #form_error {
  color: #912f3c;
  margin-bottom: 30px;
}

body#order-page #order_form .steps {
  border-bottom: 1px solid #e9e8e8;
  gap: 50px;
  position: relative;
}

body#order-page #order_form .steps .step-tab {
  box-sizing: border-box;
  width: 150px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  opacity: .5;
  padding-bottom: 8px;
  transition: all .45s ease-in-out;
  position: relative;
}

body#order-page #order_form .steps .step-tab:before {
  content: "";
  height: 1px;
  background-color: #535453;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
}

body#order-page #order_form .steps .step-tab:after {
  content: "";
  width: 100%;
  height: 1px;
  max-width: 0;
  background-color: #a27825;
  transition: all .45s ease-in-out;
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
}

body#order-page #order_form .steps .step-tab.active {
  opacity: 1;
  pointer-events: none;
}

body#order-page #order_form .steps .step-tab.active:before {
  display: none;
}

body#order-page #order_form .steps .step-tab.active:after {
  max-width: 500px;
}

body#order-page #order_form .step {
  display: none;
}

body#order-page #order_form .step.active {
  display: block;
}

body#order-page #order_form .input_group {
  color: #535453;
  flex-direction: column;
  display: flex;
}

body#order-page #order_form .input_group label {
  min-width: 150px;
  text-align: left;
  position: relative;
}

body#order-page #order_form .input_group label.required span {
  position: relative;
}

body#order-page #order_form .input_group label.required span:after {
  content: "*";
  color: #912f3c;
  position: absolute;
  right: -10px;
}

body#order-page #order_form .input_group input, body#order-page #order_form .input_group textarea, body#order-page #order_form .input_group select {
  box-sizing: border-box;
  width: 100%;
  background-color: #e9e8e8;
  border: none;
  border-radius: 4px;
  padding: 12px 16px;
}

body#order-page #order_form .input_group input:active, body#order-page #order_form .input_group input:focus, body#order-page #order_form .input_group textarea:active, body#order-page #order_form .input_group textarea:focus, body#order-page #order_form .input_group select:active, body#order-page #order_form .input_group select:focus {
  outline-color: #a27825;
}

body#order-page #order_form .input_group input:invalid, body#order-page #order_form .input_group textarea:invalid, body#order-page #order_form .input_group select:invalid {
  outline-color: #912f3c;
}

body#order-page #order_form .input_group textarea {
  resize: vertical;
}

body#order-page #order_form .help {
  margin-top: 4px;
  font-size: .875rem;
}

body#order-page #order_form .show-img {
  appearance: none;
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

body#order-page #order_form .show-img:hover {
  opacity: .85;
}

body#order-page #order_form .show-img .image {
  width: 11px;
  margin-left: 4px;
}

body#order-page #order_form .show-img .img-ref {
  width: 100%;
  height: 100%;
  opacity: 0;
  display: block;
  position: absolute;
  inset: 0;
}

body#order-page #order_form .animated-btn {
  padding: 12px 90px;
}

body#order-page #order_form .animated-btn.back {
  background-color: #53545380;
}

body#order-page #order_form .animated-btn.back:before {
  display: none;
}

body#order-page #order_form .submit {
  flex-wrap: wrap;
  gap: 20px 30px;
}

@media screen and (max-width: 992px) {
  body#order-page #order_form .submit .animated-btn {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  body#order-page section#landing .landing-content {
    width: 180px;
  }
}

.burger {
  cursor: pointer;
  padding: 3em 1.5em;
  font-size: .875rem;
}

.burger.showHitArea {
  border: 1px solid olive;
}

.burger > div {
  width: 4em;
  height: .5em;
  background: #a27825;
  border-radius: 8px;
}

.burger > div:before, .burger > div:after {
  content: "";
  width: 4em;
  height: .5em;
  background: #a27825;
  border-radius: 8px;
  transition: font-size;
  display: block;
  position: absolute;
}

.burger > div:before {
  margin-top: -1.5em;
}

.burger > div:after {
  margin-top: 1.5em;
}

.burger-cross {
  transition: all .7s cubic-bezier(.68, -.35, .265, 1.35), font-size cubic-bezier(.68, -.35, .265, 1.35);
}

.burger-cross > div {
  transition: all .1s .3s, font-size;
}

.burger-cross > div:before, .burger-cross > div:after {
  transition: all .3s .2s, font-size;
}

.burger-cross.toggled {
  transform: rotate(180deg);
}

.burger-cross.toggled > div:before {
  transform: rotate(45deg)translate(1.1em, 1em);
}

.burger-cross.toggled > div {
  background: none;
}

.burger-cross.toggled > div:after {
  transform: rotate(-45deg)translate(1.1em, -1em);
}

.burger-arrow {
  transition: all .7s cubic-bezier(.68, 0, .265, 1.25), font-size cubic-bezier(.68, 0, .265, 1.25);
}

.burger-arrow > div {
  transition: all .5s, font-size;
}

.burger-arrow > div:before, .burger-arrow > div:after {
  transform-origin: 0 0;
  transition: all .4s .2s, font-size;
}

.burger-arrow.toggled {
  transform: rotate(180deg);
}

.burger-arrow.toggled > div:before {
  width: 3em;
  transform: rotate(-45deg)translate(-1.4em, 1.1em);
}

.burger-arrow.toggled > div:after {
  width: 3em;
  transform: rotate(45deg)translate(-1em, -1.2em);
}

header {
  z-index: 10000;
  padding: 40px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

header.light nav .links .right {
  color: #535453 !important;
}

header.scrolled {
  background: #ffffffbf;
}

header.scrolled nav .links #right-links {
  color: #535453;
}

header #toggle-nav, header .burger-outer {
  display: none;
}

header nav {
  gap: 24px;
}

header nav .brand {
  width: clamp(75px, 18vw, 320px);
  color: #535453;
}

header nav .brand svg, header nav .brand img {
  width: 100%;
  height: auto;
  display: block;
}

header nav .brand svg path, header nav .brand svg polygon, header nav .brand img path, header nav .brand img polygon {
  fill: currentColor;
}

header nav .links {
  flex: 1 0 auto;
  margin-left: 50px;
}

header nav .links .left {
  gap: 20px;
}

header nav .links #right-links {
  color: #e9e8e8;
}

header nav .links .products-links {
  -webkit-user-select: none;
  user-select: none;
  z-index: 99999;
  position: relative;
}

header nav .links .products-links:hover .inner {
  max-height: 1000px;
}

header nav .links .products-links h5 {
  font-size: 1rem;
}

header nav .links .products-links .inner {
  width: 270px;
  max-height: 0;
  text-align: left;
  background-color: #ffffffe6;
  transition: all .2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translateY(2rem);
}

header nav .links .products-links .inner .title {
  padding: 10px;
  font-weight: bold;
}

header nav .links .products-links .inner .title .link-jp {
  margin-left: 12px;
  font-size: .875rem;
  display: inline-flex;
}

header nav .links .products-links .inner .sub-sections {
  max-height: 80vh;
  overflow-y: scroll;
}

header nav .links .products-links .inner li.product {
  padding: 10px 16px;
  font-size: .9rem;
}

header nav .links .products-links .inner a.sub-link {
  padding: 4px;
  position: relative;
}

header nav .links .products-links .inner a.sub-link:after {
  content: "";
  width: 100%;
  height: 2px;
  transform-origin: 100%;
  background-color: #535453;
  transition: all .3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scale(0);
}

header nav .links .products-links .inner a.sub-link.active {
  color: #a27825;
}

header nav .links .products-links .inner a.sub-link.active:after {
  background-color: #a27825;
}

header nav .links .products-links .inner a.sub-link:hover, header nav .links .products-links .inner a.sub-link.active {
  background-color: #ffffff4d;
}

header nav .links .products-links .inner a.sub-link:hover:after, header nav .links .products-links .inner a.sub-link.active:after {
  transform: scale(1);
}

header nav .links .languages {
  margin-right: 50px;
}

header nav .links .languages .language {
  -webkit-user-select: none;
  user-select: none;
  padding: 0 10px;
}

header nav .links .languages .language.active {
  color: #a27825;
  pointer-events: none;
}

header nav .links .languages .language:hover {
  opacity: .85;
}

header nav .links .link {
  height: 100%;
  text-align: center;
  padding: 0;
  display: block;
  position: relative;
}

header nav .links .link:after {
  content: "";
  width: 100%;
  height: 2px;
  transform-origin: 100%;
  background-color: #535453;
  border-radius: 8px;
  transition: all .3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scale(0);
}

header nav .links .link.active {
  color: #a27825;
}

header nav .links .link.active:after {
  background-color: #a27825;
}

header nav .links .link:hover:after, header nav .links .link.active:after {
  transform: scale(1);
}

@media screen and (max-width: 768px) {
  header {
    z-index: 200;
    background: none;
    padding: 20px 0;
    position: absolute;
  }

  header #toggle-nav {
    position: absolute;
  }

  header #toggle-nav:checked ~ .links {
    opacity: 1;
    transform: scale(1);
  }

  header .burger-outer {
    width: 44px;
    height: 44px;
    box-sizing: border-box;
    z-index: 999;
    background-color: #ffffffbf;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: 0;
    padding: 0;
    display: flex;
    position: fixed;
    top: 8px;
    right: 8px;
  }

  header .burger-outer .burger-container {
    transform: scale(.55);
  }

  header nav .brand {
    width: 120px;
  }

  header nav .links {
    z-index: 200;
    opacity: 0;
    color: #e9e8e8;
    transform-origin: 100% 0;
    background-color: #535453;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 0;
    transition: all .5s, opacity .1s;
    display: flex;
    position: fixed;
    inset: 0;
    transform: scale(0);
  }

  header nav .links .left {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  header nav .links .right {
    width: 100%;
    flex-direction: column-reverse;
    margin-top: 10px;
    color: #e9e8e8 !important;
  }

  header nav .links .right a.link {
    align-self: flex-start;
  }

  header nav .links .right .languages {
    align-self: center;
    margin-top: 20px;
    margin-right: 0;
  }

  header nav .links .products-links {
    text-align: left;
    cursor: inherit;
  }

  header nav .links .products-links h5 {
    font-size: 1.2rem;
  }

  header nav .links .products-links .inner {
    max-height: 1000px;
    width: auto;
    background: none;
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }

  header nav .links .products-links .inner .sub-section.opened .title:after {
    transform: translateY(-50%)rotate(180deg);
  }

  header nav .links .products-links .inner .sub-section.opened ul.products {
    max-height: 25vh;
    overflow-y: scroll;
  }

  header nav .links .products-links .inner .title {
    padding: 20px 12px;
    position: relative;
  }

  header nav .links .products-links .inner .title:after {
    content: "▼";
    transform-origin: center;
    font-size: .7rem;
    transition: all .3s ease-in-out;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  header nav .links .products-links .inner ul.products {
    max-height: 0;
    padding-left: 10px;
    transition: all .3s ease-in-out;
    overflow: hidden;
  }

  header nav .links .products-links .inner li.product {
    padding: 8px 12px;
  }

  header nav .links .products-links .inner a.sub-link.active, header nav .links .products-links .inner a.sub-link:hover {
    background: none;
  }

  header nav .links .link, header nav .links .sub-link {
    justify-content: center;
    padding-left: 40px;
    font-size: 1.15rem;
  }

  header nav .links .link:after, header nav .links .sub-link:after {
    bottom: -2px;
  }

  header nav .sns {
    flex-direction: column;
    align-self: center;
    margin-top: 10px;
    display: flex;
  }

  header nav .sns .sns-links {
    gap: 20px;
    margin-top: 16px;
  }

  header nav .sns .sns-links .sns-link {
    height: 18px;
  }

  header nav .sns .sns-links .sns-link svg {
    height: 100%;
    width: auto;
    display: block;
  }
}

.breadcrumbs {
  z-index: 100;
  background-color: #fff;
  margin: auto;
  padding: 30px 0;
  position: relative;
}

.breadcrumbs .separator {
  margin: 0 8px;
}

.breadcrumbs .breacrumb {
  position: relative;
}

.breadcrumbs .breacrumb:last-of-type {
  color: #a27825;
  pointer-events: none;
}

.breadcrumbs .breacrumb:after {
  content: "";
  width: 100%;
  height: 2px;
  transform-origin: 0;
  background-color: #535453;
  border-radius: 2px;
  transition: all .3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scale(0);
}

.breadcrumbs .breacrumb:hover {
  color: #535453;
}

.breadcrumbs .breacrumb:hover:after {
  transform: scale(1);
}

.footer {
  z-index: 10;
  background-color: #fff;
  position: relative;
}

.footer a.contact {
  width: 100%;
  display: block;
  position: relative;
}

.footer a.contact .background img {
  max-width: 100%;
  display: block;
}

.footer a.contact .labels {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
}

.footer a.contact .labels h2, .footer a.contact .labels h3 {
  background-color: #0c0d0d80;
  padding: 2px 8px;
}

.footer a.contact .labels h3 {
  font-style: italic;
}

.footer a.contact .labels h2 {
  background-color: #0c0d0d80;
  margin-left: 50px;
}

.footer footer {
  color: #e9e8e8;
  background-color: #535453;
  padding: 80px 0;
}

.footer footer .footer-header {
  border-bottom: 1px solid #e9e8e8;
  padding-bottom: 12px;
}

.footer footer .footer-header .brand {
  width: clamp(75px, 18vw, 320px);
  display: block;
}

.footer footer .footer-header .brand svg {
  width: 100%;
  height: auto;
  display: block;
}

.footer footer .footer-header .brand svg path, .footer footer .footer-header .brand svg polygon {
  fill: currentColor;
}

.footer footer .footer-header .sns {
  gap: 16px;
  padding-right: 50px;
}

.footer footer .footer-header .sns span {
  margin-right: 30px;
}

.footer footer .footer-header .sns .sns-links {
  gap: 16px;
}

.footer footer .footer-header .sns .sns-link {
  height: 20px;
}

.footer footer .footer-header .sns .sns-link svg {
  height: 100%;
  width: auto;
  display: block;
}

.footer footer .footer-navigation {
  gap: clamp(32px, 5vw, 120px);
}

.footer footer .footer-navigation a {
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
}

.footer footer .copyright, .footer footer .privacy {
  display: block;
}

@media screen and (max-width: 768px) {
  .footer a.contact .background img {
    height: 40vw;
    object-fit: cover;
    object-position: center;
  }

  .footer a.contact .labels {
    right: 10px;
  }

  .footer a.contact .labels h3 {
    text-align: right;
    padding: 0;
    font-size: 1.5rem;
  }

  .footer a.contact .labels h2 {
    margin-left: 10px;
    padding: 4px;
    font-size: 1rem;
  }

  .footer footer {
    margin-top: 20px;
    padding-top: 60px;
    padding-bottom: 20px;
  }

  .footer footer .footer-header {
    border: none;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .footer footer .footer-header .brand {
    width: clamp(150px, 18vw, 320px);
  }

  .footer footer .footer-header .sns {
    justify-content: flex-end;
    gap: 12px;
    margin-top: 30px;
    padding-bottom: 6px;
    padding-left: 0;
  }

  .footer footer .footer-header .sns span {
    margin-right: 10px;
    font-size: 1rem;
  }

  .footer footer .footer-header .sns .sns-links {
    justify-content: space-between;
    gap: 24px;
  }

  .footer footer .footer-header .sns .sns-link {
    height: 22px;
  }

  .footer footer .footer-navigation {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
    margin-top: 20px;
    padding-left: 0;
    display: grid;
  }

  .footer footer .footer-navigation .products {
    margin-top: 10px;
  }

  .footer footer .footer-navigation .column {
    align-items: flex-start;
  }

  .footer footer .footer-navigation h4, .footer footer .footer-navigation a.row, .footer footer .footer-navigation a.link-jp {
    padding: 0 4px;
  }

  .footer footer .footer-navigation a.row, .footer footer .footer-navigation a.link-jp {
    justify-content: flex-start;
    display: inline-flex;
    position: relative;
  }

  .footer footer .footer-navigation a.row:after, .footer footer .footer-navigation a.link-jp:after {
    content: "";
    width: 100%;
    height: 1px;
    opacity: .2;
    background-color: #e9e8e8;
    position: absolute;
    bottom: -2px;
    left: 0;
  }

  .footer footer .footer-navigation a.row:not(:last-child), .footer footer .footer-navigation a.link-jp:not(:last-child) {
    margin-bottom: 12px;
  }

  .footer footer .footer-navigation .font-md {
    font-size: 1rem;
  }

  .footer footer .footer-navigation .link-jp {
    font-size: .875rem;
  }

  .footer footer .copyright, .footer footer .privacy {
    font-size: .75rem;
  }

  .footer footer .privacy {
    text-decoration: underline;
  }
}

@media screen and (max-width: 576px) {
  .footer footer .footer-header .sns {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 24px;
  }

  .footer footer .footer-navigation {
    grid-template-columns: 1fr;
    padding-left: 24px;
  }
}

/*# sourceMappingURL=index.04da95fe.css.map */
